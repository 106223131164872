import axios from "axios";
import AWS from "aws-sdk";


export interface InfoCognito {
  email?: string;
  password?: string;
  newPassword?: string;
  username?: string;
  terms_accepted?: any;
  confirmationCode?: any;
  refreshToken?: string;
  accessToken?: string;
}

export const REQUESTS_BASE = {
  api: `${process.env.REACT_APP_COGNITO_API}`,
  headers: {
    contentType: "application/x-amz-json-1.1",
    target: "AWSCognitoIdentityProviderService",
  },
  clientId: `${process.env.REACT_APP_COGNITO_CLIENT_ID}`,
};

export const createAccount = (info: InfoCognito) =>
  axios({
    method: "post",
    url: REQUESTS_BASE.api,
    headers: {
      "Content-Type": `${REQUESTS_BASE.headers.contentType}`,
      "X-Amz-Target": `${REQUESTS_BASE.headers.target}.SignUp`,
    },
    data: {
      ClientId: `${REQUESTS_BASE.clientId}`,
      Username: info.email,
      Password: info.password,
      UserAttributes: [
        {
          Name: "name",
          Value: info.username,
        },
        {
          Name: "custom:terms_accepted",
          Value: info.terms_accepted,
        },
      ],
    },
  });

export const loginUser = (info: InfoCognito) =>
  axios({
    method: "post",
    url: `${REQUESTS_BASE.api}`,
    headers: {
      "Content-Type": `${REQUESTS_BASE.headers.contentType}`,
      "X-Amz-Target": `${REQUESTS_BASE.headers.target}.InitiateAuth`,
    },
    data: {
      AuthFlow: "USER_PASSWORD_AUTH",
      ClientId: `${REQUESTS_BASE.clientId}`,
      AuthParameters: {
        USERNAME: info.username,
        PASSWORD: info.password,
      },
    },
  });

export const forgotPassword = (info: InfoCognito) =>
  axios({
    method: "post",
    url: REQUESTS_BASE.api,
    headers: {
      "Content-Type": "application/x-amz-json-1.1",
      "X-Amz-Target": "AWSCognitoIdentityProviderService.ForgotPassword",
    },
    data: {
      ClientId: `${REQUESTS_BASE.clientId}`,
      Username: info.username,
    },
  });

export const confirmPassword = (info: InfoCognito) =>
  axios({
    method: "post",
    url: REQUESTS_BASE.api,
    headers: {
      "Content-Type": "application/x-amz-json-1.1",
      "X-Amz-Target": "AWSCognitoIdentityProviderService.ConfirmForgotPassword",
    },
    data: {
      ClientId: `${REQUESTS_BASE.clientId}`,
      Username: info.username,
      ConfirmationCode: info.confirmationCode,
      Password: info.newPassword,
    },
  });

export const resetToken = (info: InfoCognito) =>
  axios({
    method: "post",
    url: REQUESTS_BASE.api,
    headers: {
      "Content-Type": "application/x-amz-json-1.1",
      "X-Amz-Target": "AWSCognitoIdentityProviderService.InitiateAuth",
    },
    data: {
      AuthFlow: "REFRESH_TOKEN_AUTH",
      ClientId: `${REQUESTS_BASE.clientId}`,
      AuthParameters: {
        REFRESH_TOKEN: info.refreshToken,
      },
    },
  });

export const logout = (info: InfoCognito) =>
  axios({
    method: "post",
    url: REQUESTS_BASE.api,
    headers: {
      "Content-Type": "application/x-amz-json-1.1",
      "X-Amz-Target": "AWSCognitoIdentityProviderService.GlobalSignOut",
    },
    data: {
      AccessToken: info.accessToken,
    },
  });

  export const resendEmail = (info:InfoCognito) =>
  axios({
    method: "post",
    url: `${process.env.REACT_APP_COGNITO_API}`,
    headers: {
      "Content-Type": "application/x-amz-json-1.1",
      "X-Amz-Target": "AWSCognitoIdentityProviderService.ResendConfirmationCode"
    },
    data: {
      ClientId: `${process.env.REACT_APP_COGNITO_CLIENT_ID}`,
      Username: info.username
    }
  });
  
  //MFA
  const cognito = new AWS.CognitoIdentityServiceProvider({ region: "us-east-1" });

  export const checkMfaStatus = async (accessToken: string) => {
    try {
      const response = await cognito.getUser({ AccessToken: accessToken }).promise();
      const mfaEnabled = response.UserMFASettingList?.includes("SOFTWARE_TOKEN_MFA"); // Verifica se MFA está ativado
      return mfaEnabled;
    } catch (error) {
      console.error("Erro ao verificar MFA:", error);
      return false; // Em caso de erro, assume que MFA não está ativado
    }
  };
