import React, { FC, useState } from "react";
import { connect } from "react-redux";
import { OnboardingContainer } from "./styles"
import PasswordReset from "./components/PasswordReset";
import CreateAccount from "./CreateAccount";
import RecoverPassword from "./components/RecoverPassword";
import ResendEmailScreen from "./components/ResendEmailScreen";
import ResendEmailForm from "./components/ResendEmailForm";
import Login from "./Login";
import {
  updateError,
  updateCreateSuccess,
  updateRecoverSuccess,
  verifyEmailExisting,
} from "../../dataflow/modules/auth-module";
import HomeMaintenence from "./Maintenance";
import { eventCategory, logEvent } from "../../util/analytics/index";
const mapStateToProps = (state: any) => ({
  error: state.auth.error,
  createSuccess: state.auth.createSuccess,
  recoverSuccess: state.auth.recoverSuccess,
  isEmailExisting: state.auth.verifyEmailExisting,
});
const mapDispatchToProps = (dispatch: any) => ({
  updateError: (info: any) => {
    dispatch(updateError(info));
  },
  updateCreateSuccess: (info: any) => {
    dispatch(updateCreateSuccess(info));
  },
  updateRecoverSuccess: (info: any) => {
    dispatch(updateRecoverSuccess(info));
  },
  verifyEmailExisting: (info: any) => {
    dispatch(verifyEmailExisting(info));
  },
});

interface OnboardingProps {
  history?: any;
}
const Onboarding: FC<OnboardingProps> = ({
  history
}) => {
  const [currentScreen, setCurrentScreen] = useState("login");
  const [createdEmail, setCreatedEmail] = useState("");
  const [resetEmail, setResetEmail] = useState(null)
  const [resend, setResend] = useState(false);

  const handleSetCurrentScreen = (_currentScreen: any) => {
    setCurrentScreen(_currentScreen);
  };
  const handleCreate = () => {
    if (currentScreen !== "create") {
      setCurrentScreen("create");
    }
    logEvent(
      eventCategory.buttonClick,
      'navegar para formulário de criação de conta',
      'Criar conta'
    );
  };
  const handleRecoverPassword = () => {
    if (currentScreen !== "recoverPassword") {
      setCurrentScreen("recoverPassword");
    }
    logEvent(
      eventCategory.buttonClick,
      'Recuperar Senha',
      'Recuperar Senha'
    );
  };

  const handlePasswordReset = () => {
    if (currentScreen !== "password") {
      setCurrentScreen("password")
    }
  };
  const handleResendEmail = (email: string) => {
    if (currentScreen !== "resendEmail") {
      setCurrentScreen("resendEmail")
      setCreatedEmail(email)
    }
  };
  const handleResendEmailForm = () => {
    if (currentScreen !== "resendEmailForm") {
      setCurrentScreen("resendEmailForm")
    }
  };
  const handleBackLogin = () => {
    if (currentScreen !== "login") {
      setCurrentScreen("login")
      setCurrentScreen("mfaCodePage");
    }
    logEvent(
      eventCategory.linkClick,
      'Voltar ao Login',
      'Voltar ao Login'
    );
  };
  
  const handleSetResetEmail = (resetEmail: any) => {
    setResetEmail(resetEmail)
  };
  const renderCurrentScreen = () => {
    switch (currentScreen) {
      case "create":
        return (
          <CreateAccount
            handleBackLogin={handleBackLogin}
            handleResendEmail={handleResendEmail}
            handleResend={(r) => setResend(r)}
          />
        );
      case "recoverPassword":
        return (
          <RecoverPassword
            handleBackLogin={handleBackLogin}
            setCurrentScreen={handleSetCurrentScreen}
            setResetEmail={handleSetResetEmail}
          />
        );
      case "password":
        return (
          <PasswordReset
            // handleBackLogin={handleBackLogin}
            setCurrentScreen={handleSetCurrentScreen}
            resetEmail={resetEmail}
          />
        );
      case "resendEmail":
        return (
          <ResendEmailScreen
            createdEmail={createdEmail}
            handleBackLogin={handleBackLogin}
            resend={resend}
          />
        );
      case "resendEmailForm":
        return (
          <ResendEmailForm
            handleBackLogin={handleBackLogin}
            setCreatedEmail={setCreatedEmail}
            setCurrentScreen={setCurrentScreen}
            handleResend={(r) => setResend(r)}
          />
        );
      case "pne-access":
        return (
          <Login
            handleCreate={handleCreate}
            handleRecoverPassword={handleRecoverPassword}
            handlePasswordReset={handlePasswordReset}
            history={history}
            handleResendEmailForm={handleResendEmailForm}
          />
        );
      case "login":
      default:
        const pathName = history.location.pathname;
        if (
          process.env.REACT_APP_MAINTENANCE === "active" &&
          pathName !== "/pne-access"
        ) {
          return <HomeMaintenence />;
        }
        return (
          <Login
            handleCreate={handleCreate}
            handleRecoverPassword={handleRecoverPassword}
            handlePasswordReset={handlePasswordReset}
            history={history}
            handleResendEmailForm={handleResendEmailForm}
          />
        );
    }
  };
  return (
    <OnboardingContainer screen={currentScreen}>
      {renderCurrentScreen()}
    </OnboardingContainer>
  );
}
export default connect(mapStateToProps, mapDispatchToProps)(Onboarding);