import React from 'react';
import { CardWrapper, CardTitle, Text } from '../styles';
import ApiStoreLogo from '../../../assets/img/logos/ApiStore.svg';

const CnpjInvalido = () => {
  return (
    <CardWrapper
      style={{
        width: '100%',
      }}
    >
      <img src={ApiStoreLogo} alt="Logo da ApiStore" width={80} />
      <CardTitle
        style={{
          textAlign: 'center',
        }}
      >
        {' '}
        Solicitação de Participação Reprovada
      </CardTitle>
      <Text>
        <p>
          Sua solicitação foi reprovada porque não identificamos um cadastro
          ativo no Portal Petronect.
        </p>
        <p>
          <strong>Como resolver?</strong>
        </p>
        <ol>
          <li>
            Acesse o site{' '}
            <a
              href="https://www.petronect.com.br/"
              target="_blank"
              rel="noopener noreferrer"
            >
              www.petronect.com.br
            </a>
            .
          </li>
          <li>
            Clique em <strong>"Cadastre-se"</strong> e siga as instruções para
            criar sua conta.{' '}
          </li>
          <li>
            Após a conclusão do cadastro, retorne à API Store e faça uma nova
            solicitação de participação.
          </li>
        </ol>
        <p>
          Caso tenha dúvidas, entre em contato com o suporte do{' '}
          <strong>Portal Petronect</strong>.
        </p>
        <ul>
          <li>
            <strong>4020-9876 e 4020-2460</strong>: Capitais, regiões
            metropolitanas e ligações originadas de telefone celular
          </li>
          <li>
            <strong>0800 282 8484</strong>: Demais regiões
          </li>
        </ul>
      </Text>
    </CardWrapper>
  );
};

export default CnpjInvalido;
