import React from 'react';
import {
  TextDescription,
  Text,
  TextBold,
  Link,
} from '../../../components/common-components/Accordion/styles';
import { Image } from '../styles';
import AppAutenticator from '../../../assets/img/faq/AppAutenticator.png';
import AutenticatorConfig from '../../../assets/img/faq/AutenticatorConfig.png';
import AutenticatorCode from '../../../assets/img/faq/AutenticatorCode.png';

const MFAAutenticator = () => {
  return (
    <>
      <TextDescription>
        <Text>
          Faça o download do <TextBold>Google Authenticator</TextBold> em seu
          dispositivo móvel:
        </Text>
        <ul>
          <li>
            <Link href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=pt_BR&pli=1" target="_blank">
                Google Play Store (Android)
            </Link>
          </li>
          <li>
            <Link href="https://apps.apple.com/br/app/google-authenticator/id388497605" target="_blank">
                App Store (iOS)
            </Link>
          </li>
        </ul>
        <br />
        <br />
        <Image src={AppAutenticator} alt="Google Autenticator" />
        <br />
        <br />
        <Text>
            Faça login na Minha Petronect.
        </Text>
        <br />
        <br />
        <Text>
            Será exibida uma tela solicitando a ativação do MFA. Essa tela também contém os links para download do aplicativo.
        </Text>
        <br />
        <br />
        <Text>
            No aplicativo Google Authenticator, escolha a opção de adicionar uma nova conta e faça a integração:
        </Text>
        <br />
        <br />
        <ul>
            <li>
            <TextBold>Opção 1:</TextBold> Escaneie o <TextBold>QR Code</TextBold> exibido na tela da Minha Petronect.
            </li>
            <li>
            <TextBold>Opção 2:</TextBold> Insira manualmente o <TextBold>código fornecido</TextBold> na tela.
            </li>
        </ul>
        <Image src={AutenticatorConfig} alt="Autenticator Config" />
        <br/>
        <br/>
        <Text>
            Após a configuração, o Google Authenticator gerará códigos de verificação de seis dígitos. Sempre que fizer login, será necessário inserir o código exibido no app para acessar sua conta na Minha Petronect.
        </Text>
        <br/>
        <br/>
        <Image src={AutenticatorCode} alt="Autenticator Code" width={200} />
      </TextDescription>
    </>
  );
};

export default MFAAutenticator;
