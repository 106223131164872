import React, { FC, useState, useEffect } from "react";
import styled from "styled-components";
import Colors from "../../../assets/styles/colors";
import Button from "../../../components/common-components/Button";
import Modal from "../../../components/common-components/Modal/Modal";
import { eventCategory, logEvent } from "../../../util/analytics";
import AWS from "aws-sdk";
import { QRCodeSVG } from "qrcode.react";

const Title = styled.h1`
  font-weight: 700;
  font-size: 2rem;
  font-family: "Open Sans", "Avenir Next";
  color: ${Colors.blue8};
`;

const Text = styled.p`
  color: ${Colors.gray8};
  padding: 0 10px;
  font-family: "Open Sans", "Avenir Next";
  text-align:left
`;

const Box = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 2px;
`;

interface ModalNewVersionProps {
  closeModal: () => void;
}

const ModalNewVersion: FC<ModalNewVersionProps> = ({ closeModal }) => {
  const [showMFASetup, setShowMFASetup] = useState(false);
  const [secretKey, setSecretKey] = useState<string | null>(null);
  const [userCode, setUserCode] = useState<string>("");
  const [isMfaEnabled, setIsMfaEnabled] = useState<boolean>(
    JSON.parse(localStorage.getItem("mfa_enabled") || "false")
  );
  const appVersion = (window as any).appVersion;
  const accessToken = JSON.parse(localStorage.getItem("petronect_credentials") || "{}").accessToken;
  const targetDate = new Date("2025-05-28T00:00:00Z").getTime();
  const [daysLeft, setDaysLeft] = useState(calculateDaysLeft());

  useEffect(() => {
    AWS.config.update({ region: "us-east-1" });
  }, []);

  function calculateDaysLeft() {
    const now = new Date().getTime();
    const difference = targetDate - now;
    return difference > 0 ? Math.ceil(difference / (1000 * 60 * 60 * 24)) : 0;
  }

  useEffect(() => {
    const timer = setInterval(() => {
      setDaysLeft(calculateDaysLeft());
    }, 24 * 60 * 60 * 1000);

    return () => clearInterval(timer);
  }, []);

  const associateMfaToken = async () => {
    if (!accessToken) return;

    const cognito = new AWS.CognitoIdentityServiceProvider();
    try {
      const response = await cognito.associateSoftwareToken({ AccessToken: accessToken }).promise();
      setSecretKey(response.SecretCode || "");
    } catch (error) {
      console.error("Erro ao associar MFA:", error);
    }
  };

  const enableMfaForUser = async () => {
    if (!accessToken) return;

    const cognito = new AWS.CognitoIdentityServiceProvider();
    try {
      await cognito.setUserMFAPreference({
        AccessToken: accessToken,
        SoftwareTokenMfaSettings: {
          Enabled: true,
          PreferredMfa: true,
        },
      }).promise();

      setIsMfaEnabled(true);
      localStorage.setItem("mfa_enabled", JSON.stringify(true));
      alert("MFA ativado com sucesso!");
    } catch (error) {
      console.error("Erro ao ativar MFA no Cognito:", error);
      alert("Erro ao ativar MFA. Tente novamente.");
    }
  };

  const verifyMfaToken = async () => {
    if (!accessToken || !userCode) return;

    const cognito = new AWS.CognitoIdentityServiceProvider();
    try {
      const response = await cognito.verifySoftwareToken({
        AccessToken: accessToken,
        UserCode: userCode,
      }).promise();

      if (response.Status === "SUCCESS") {
        enableMfaForUser();
      } else {
        alert("Código inválido. Tente novamente.");
      }
    } catch (error) {
      console.error("Erro ao verificar MFA:", error);
    }
  };

  const inputStyle = {
    width: "150px",
    outline: "none",
    margin: "0.5rem 0",
    font: "400 1rem 'Open Sans'",
    border: secretKey?.trim() === "" ? "1px solid #E74C3C" : "1px solid #2ECC71",
    padding: "1.25rem calc(1rem - 2px)",
    color: "#7F8C8D",
    background: "#ECF0F1",
    borderRadius: "4px",
  };

  return (
    <Modal width={'50%'} onBlur={() => { logEvent(eventCategory.modalOpen, 'click modal nova versão App', `click modal nova versão App`); }} closeModal={closeModal} overlay>
      {!showMFASetup ? (
        <>
          <Title>Múltiplo Fator de Autenticação (MFA) - Minha Petronect</Title>
          
          <Box>
            <Text>
              <Text>
                <strong>O que é a Autenticação Multifator (MFA)?</strong> Ela adiciona
                uma camada extra de segurança à sua conta, exigindo um código gerado no
                seu celular além da senha ao fazer login.
              </Text>
              <Text>
                Para ativar o MFA, baixe o aplicativo <strong>Google Authenticator</strong> em seu celular. Para mais informações,{" "}
                <a
                  href="https://support.google.com/accounts/answer/1066447?hl=pt-BR"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: Colors.blue6, textDecoration: "underline" }}
                >
                  clique aqui
                </a>.
              </Text>
              <Text>
                
              </Text>
              <Text>
                Você tem <strong>{daysLeft} dia(s)</strong> para ativar a autenticação multifator. Após esse período, o acesso será restrito.
              </Text>
            </Text>
          </Box>

          <div style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
            {!isMfaEnabled && (
              <Button
                background={Colors.blue6}
                width={"25%"}
                height={"56px"}
                fontWeight={"400"}
                margin={"10px"}
                onClick={() => {
                  setShowMFASetup(true);
                  associateMfaToken();
                }}
              >
                Habilitar MFA
              </Button>
            )}
            <Button background={Colors.blue6} width={"25%"} height={"56px"} fontWeight={"400"} margin={"10px"} onClick={closeModal}>
            Cancelar
            </Button>
          </div>
        </>
      ) : (
        <>
          <Title>Configure o aplicativo móvel</Title>
          <div style={{ textAlign: "left", width: "100%", padding: "2px", fontFamily: "Open Sans, Avenir Next" }}>
          <Text>Realize as seguintes etapas para configurar seu aplicativo móvel.</Text>
          <ol style={{ paddingLeft: "20px", marginTop: "10px" }}>
            <li>Instale o aplicativo <strong>Google Authenticator</strong> para <a href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2" target="_blank" rel="noopener noreferrer">Android</a> ou <a href="https://apps.apple.com/us/app/google-authenticator/id388497605" target="_blank" rel="noopener noreferrer">iOS</a>.</li>
            <li>No aplicativo, adicione uma conta.</li>
            <li>Digitalize a imagem abaixo:</li>
          </ol>
          <Box>
    {secretKey ? (
      <div style={{ display: "flex", flexDirection: "column", margin: "10px" }}>
        <QRCodeSVG value={`otpauth://totp/PetronectStore?secret=${secretKey}&issuer=PetronectStore`} size={150} />
      </div>
    ) : (
      <Text>Gerando QR Code...</Text>
    )}
  </Box>

  <Text>Se não conseguir digitalizar a imagem, insira as seguintes informações no aplicativo:</Text>
  <Text><strong>Código:</strong> {secretKey}</Text>
  <Text>Se o aplicativo exibir um código de seis dígitos, significa que você terminou.</Text>

  <Box style={{ padding: "20px" }}>
    <label 
      htmlFor="mfaCode"
      style={{
        font: "500 0.75rem 'Eurostile', 'Open Sans', sans-serif",
        margin: "0 0 0.25rem 0",
        color: "#2ECC71",
      }}
    >
      Digite o código gerado no Google Authenticator
    </label>
    <input
      type="text"
      value={userCode}
      onChange={(e) => {
        const newValue = e.target.value;
        if (/^\d{0,6}$/.test(newValue)) {
          setUserCode(newValue);
        }
      }}
      placeholder="Código MFA"
      style={inputStyle}
      maxLength={6}
    />
  </Box>
  </div>

          <div style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
            <Button background={Colors.blue6} width={"25%"} height={"56px"} fontWeight={"400"} margin={"10px"} onClick={verifyMfaToken}>
              Validar Código
            </Button>
            <Button background={Colors.blue6} width={"25%"} height={"56px"} fontWeight={"400"} margin={"10px"} onClick={closeModal}>
              Cancelar
            </Button>
          </div>
        </>
      )}
    </Modal>
  );
};

export default ModalNewVersion;
