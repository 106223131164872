import React, { FC, useState } from "react";
/* import Accordion from "../../components/common-components/Accordion/Accordion"; */
import {
  Container,
  Title,
  ContentContainer
} from "./styles";
import { connect } from "react-redux";
import {
  sendMessage,
  changeStatus,
} from "../../dataflow/thunks/support-thunk";
import { FaqContent } from "./content";
import { decodedToken } from "../../util/devUtilities";
import { TourText } from "../Auth/Login/styles";
import { useHistory } from "react-router-dom";
import Accordion from 'react-bootstrap/Accordion';


const mapStateToProps = (state: any) => ({
  supportResponse: state.support.supportResponse,
});

const mapDispatchToProps = (dispatch: any) => ({
  sendMessage: (info: any) => dispatch(sendMessage(info)),
  changeStatus: (info: any) => dispatch(changeStatus(info)),
});

const Faq: FC = () => {
  const [activeKey, setActiveKey] = useState(null);
  // const send_message_to_support = (message:any)=> {
  //   props.supportResponse.status === "writing"
  //     ? props.sendMessage(message)
  //     : props.changeStatus(props.supportResponse.status);
  // }


  // let helperType = props.supportResponse.status==='writing'? "normal": "custom";

  const history = useHistory();
  const goBack = () => {
    history.goBack();
  }

  const handleAccordionToggle = (eventKey: any) => {
    setActiveKey(activeKey === eventKey ? null : eventKey);
  };

  return (
    <>
      <Container>
        <Title>{`Olá${true ? '' : `${decodedToken('idToken')?.name}`}, aqui você pode encontrar respostas para as dúvidas mais frequentes:`}</Title>
        <ContentContainer>
          {FaqContent.map((element:any, index:any) => {
            return ( 
              <Accordion  activeKey={activeKey} onSelect={handleAccordionToggle}>
              <Accordion.Item key={index} eventKey={index.toString()}>
                <Accordion.Header >
                  <h1 style={{ fontSize: '16px', fontWeight: '800', fontFamily: "sans-serif", color: "#116DA0", }}>{element.title}</h1></Accordion.Header>
                <Accordion.Body style={{ fontFamily: "sans-serif", fontSize: "14px", color: "#505050",}}>
                  <div>
                    <p>{element?.description}</p>
                    <p style={{ fontWeight: '800', fontFamily: "sans-serif", color: "#116DA0", }}>{element?.subtitle}</p>
                    <p>{element?.subdescription}</p>
                    <p style={{ fontWeight: '800', fontFamily: "sans-serif", color: "#116DA0", }}>{element?.subtitle2}</p>
                    <p>{element?.subdescription2}</p>
                    <p style={{ fontWeight: '800', fontFamily: "sans-serif", color: "#116DA0", }}>{element?.subtitle3}</p>
                    <p>{element?.subdescription3}</p>   
                    <p style={{ fontWeight: '800', fontFamily: "sans-serif", color: "#116DA0", }}>{element?.subtitle4}</p>
                    <p>{element?.subdescription4}</p>
                    <p style={{ fontWeight: '800', fontFamily: "sans-serif", color: "#116DA0", }}>{element?.subtitle5}</p>
                    <p>{element?.subdescription5}</p>                        
                    <p style={{ fontWeight: '800', fontFamily: "sans-serif", color: "#116DA0", }}>{element?.subtitle6}</p>
                    <p>{element?.subdescription6}</p>                        
                  </div>
                </Accordion.Body>                
              </Accordion.Item>
            </Accordion>
              );
            })} 
        </ContentContainer>
        
      </Container>
      <TourText
          onClick={() => goBack()}
        >
          <span
            style={{
              fontWeight: "bold",
              cursor: "pointer",
              textDecoration: "underline",
              textAlign: 'center'
            }}
          >
            Voltar
          </span>
        </TourText>

      {/* <Help
          layoutStyle={props.layoutStyle || "dark"}
          helpType={props.supportResponse.status}
          feedbackMessage={props.supportResponse.message}
          sendMessage={(message) => send_message_to_support(message)}
        /> */}
    </>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Faq);
