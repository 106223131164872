import React, { FC } from 'react';
import { ContainerPage } from './styles';
import FaqParticipeApiStore from './FaqParticipeApiStore';
import ParticipeApiStoreForm from './ParticipeApiStoreForm';

const ParticipeApiStorePage: FC = () => {
  return (
    <ContainerPage>
      <FaqParticipeApiStore />
      <ParticipeApiStoreForm/>
    </ContainerPage>
  );
};

export default ParticipeApiStorePage;
