interface DefineCookieProps {
    key: string;
    value: string;
    days: number;
    secure?: boolean;
    sameSite?: 'Strict' | 'Lax' | 'None';
  }
  
  const defineCookie = ({
    key,
    value,
    days,
    secure = true,
    sameSite = 'Lax',
  }: DefineCookieProps): void => {
    const cookieValue = String(value);
  
    const data = new Date();
    data.setTime(data.getTime() + days * 24 * 60 * 60 * 1000);
    const expires = `expires=${data.toUTCString()}`;
  
    let cookie = `${key}=${cookieValue}; ${expires}; path=/`;
  
    if (secure) {
      cookie += '; Secure';
    }
  
    if (sameSite) {
      cookie += `; SameSite=${sameSite}`;
    }
  
    document.cookie = cookie;
  };
  
  export default defineCookie;