import { useState } from 'react';
import { formatCNPJ, formatCPF } from '../util/formatCNPJ';
import SendEmailApiStore from '../dataflow/thunks/SendEmailApiStore';
import 'react-toastify/dist/ReactToastify.css';
import { formatTell } from '../util/formatTell';
import capitalizeFirstLetter from '../util/CapitalizeFirstLetter';

interface FormData {
  nome: string;
  sobrenome: string;
  email: string;
  telefone: string;
  cpf: string,
  cnpj: string;
  aceite: boolean;
}

interface Errors {
  [key: string]: string;
}

const useApiStore = (initialData: FormData) => {
  const [formData, setFormData] = useState<FormData>(initialData);
  const [errors, setErrors] = useState<Errors>({});
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);

  const isValidEmail = (email: string) => {
    const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return regex.test(email);
  };

  const isValidNome = (nome: string) => {
    return nome.trim().length > 0;
  };

  const isValidSobreNome = (sobrenome: string) => {
    return sobrenome.trim().length > 0;
  };

  const isValidTell = (telefone: string) => {
    const cleanedTell = telefone.replace(/[^\d]/g, '');
    const regex = /^\d{13}$/;
    return regex.test(cleanedTell);
  };

  const isValidCpf = (cpf: string) => {
    const cleanedCpf = cpf.replace(/[^\d]/g, '');
    const regex = /^\d{11}$/;
    return regex.test(cleanedCpf);
  };

  const isValidCnpj = (cnpj: string) => {
    const cleanedCnpj = cnpj.replace(/[^\d]/g, '');
    const regex = /^\d{14}$/;
    return regex.test(cleanedCnpj);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    let validationErrors: Errors = {};

    if (!isValidNome(formData.nome)) {
      validationErrors.nome = 'Nome é obrigatório';
    }
    if (!isValidSobreNome(formData.sobrenome)) {
      validationErrors.sobrenome = 'Sobrenome é obrigatório';
    }
    if (!isValidEmail(formData.email)) {
      validationErrors.email = 'Email inválido ex: example@example.com';
    }
    if (!isValidTell(formData.telefone)) {
      validationErrors.telefone = 'Telefone inválido';
    }
    if (!isValidCpf(formData.cpf)) {
      validationErrors.cpf = 'CPF inválido';
    }
    if (!isValidCnpj(formData.cnpj)) {
      validationErrors.cnpj = 'CNPJ inválido';
    }
    if (!formData.aceite) {
      validationErrors.aceite = 'Aceite nossos termos para prosseguir.';
    }

    if (Object.keys(validationErrors).length === 0) {
      try {
        SendEmailApiStore(formData);
        setIsSubmitting(true);
        setTimeout(() => {setSuccess(true)}, 2000)
      } catch (error) {
        setIsSubmitting(false);
        setSuccess(false)
      }
    } else {
      setErrors(validationErrors);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setErrors((prevState) => {
      const newErrors = { ...prevState };
      delete newErrors[name];
      return newErrors;
    });

    if (name === 'nome') {
      const formattedNome = capitalizeFirstLetter(value);
      setFormData((prevState) => ({
        ...prevState,
        [name]: formattedNome,
      }));
    } else if (name === 'sobrenome') {
      const formattedSobreNome = capitalizeFirstLetter(value);
      setFormData((prevState) => ({
        ...prevState,
        [name]: formattedSobreNome,
      }));
    } else if (name === 'telefone') {
      const formattedTell = formatTell(value);
      setFormData((prevState) => ({
        ...prevState,
        [name]: formattedTell,
      }));
    } else if (name === 'cnpj') {
      const formattedCnpj = formatCNPJ(value);
      setFormData((prevState) => ({
        ...prevState,
        [name]: formattedCnpj,
      }));
    } else if (name === 'cpf') {
      const formattedCpf = formatCPF(value);
      setFormData((prevState) => ({
        ...prevState,
        [name]: formattedCpf,
      }));
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;

    setErrors((prevState) => {
      const newErrors = { ...prevState };
      delete newErrors[name];
      return newErrors;
    });

    setFormData((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
  };

  return {
    formData,
    errors,
    isSubmitting,
    handleSubmit,
    handleChange,
    handleCheckboxChange,
    success,
  };
};

export default useApiStore;
