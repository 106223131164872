import React, { FC } from 'react';
import logoMinhaPetronect from '../../../../assets/img/logos/LogoPNE.png';
import logoApiStore from '../../../../assets/img/logos/ApiStore.svg';
import styled from 'styled-components';
import background from './20220726_cardLives.png';
import Colors from '../../../../assets/styles/colors';

const SlideCardLiveContainer = styled.div`
width: 85%;
height: 100vh;
margin: auto;
display: flex;
text-align: start;
flex-direction: column;
justify-content: center;
align-items: center;
background-image: url(${background});
background-position: center;
background-repeat: no-repeat;
background-size: 100%;
flex-wrap: nowrap;
`;

const LogoContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 30%;
  min-width: 30%;
  padding-bottom: 0px;
`;

const BodyContainer = styled.div`
  width: 75%;
  border: solid 0px;
  box-shadow: 0px 0px 15px -5px ${Colors.black};
  background-color: ${Colors.white};
  padding: 10px;
`;
const Text = styled.div`
  margin: 5px;
  text-align: start;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  letter-spacing: 0px;
  color: ${Colors.blue10};
`;

const TextSpan = styled.span`
  color: ${Colors.blue10};
  letter-spacing: 0px;
  font: 700 14px sans-serif;

  @media (max-width: 1200px) {
    font-size: 1rem;
  }
`;

const TextSpanGreen = styled.span`
  color: ${Colors.green9};
  font: 700 18px sans-serif;
  margin: 5px 0 5px 0;

  @media (max-width: 1200px) {
    font-size: 1rem;
  }
`;

const HighlightText = styled.h3`
  color: ${Colors.green9};
  font-weight: 800;
  margin-bottom: 10px;
  margin-top: 10px;
`;

const CardApiStore: FC = () => {
  return (
    <SlideCardLiveContainer>
      <LogoContainer>
        <img
          src={logoMinhaPetronect}
          alt="Logo Minha Petronect"
          style={{
            width: '80px',
          }}
        />
        <img
          src={logoApiStore}
          alt="Logo da Api Store"
          style={{
            width: '80px',
          }}
        />
      </LogoContainer>
      <HighlightText>{`🚀 Chegou a API Store na Minha Petronect!`}</HighlightText>
      <Text style={{ textAlign: 'center', margin: '0', marginBottom: '20px' }}>
        {`Agora você pode integrar seus sistemas de forma ágil e eficiente com a `}
        <TextSpan>{`API Store`}</TextSpan>
        {`  🔗💡 `}
      </Text>
      <BodyContainer>
        <TextSpanGreen
          style={{ margin: '0 0 20px 0' }}
        >{`🔹 Benefícios:`}</TextSpanGreen>
        <Text
          style={{ marginLeft: '20px' }}
        >{`✅ Acesso rápido as oportunidades públicas`}</Text>
        <Text
          style={{ marginLeft: '20px' }}
        >{`✅ Integração simplificada com seu ERP`}</Text>
        <Text
          style={{ marginLeft: '20px',
            marginBottom: '20px',
           }}
        >{`✅ Automação de processos para mais eficiência`}</Text>
        <TextSpanGreen>{`🔹 APIs disponíveis:`}</TextSpanGreen>
        <Text
          style={{ marginLeft: '20px' }}
        >{`🔍 Consulta de Oportunidades Públicas Concluídas`}</Text>
        <Text
          style={{ marginLeft: '20px',
            marginBottom: '20px',
           }}
        >{`📢 Consulta de Oportunidades Públicas Abertas`}</Text>
        <Text style={{ textAlign: 'center', marginTop: '10px' }}>
          <TextSpan>{`📅 Já disponível!`}</TextSpan>
          {` Acesse agora pela Minha Petronect e verifique os pré-requisitos na sessão da API Store para aproveitar essa novidade!`}
        </Text>
      </BodyContainer>
    </SlideCardLiveContainer>
  );
};

export default CardApiStore;
