import React from 'react';
import {
  DocumentContainer,
  TextDocument,
  CardWrapper,
  CardTitle,
} from '../styles';
import ApiStoreLogo from '../../../assets/img/logos/ApiStore.svg';

const Documentacao = () => {
  return (
    <DocumentContainer>
      <CardWrapper
        style={{
          width: '100%',
        }}
      >
        <img src={ApiStoreLogo} alt="Logo da ApiStore" width={80} />
        <CardTitle
          style={{
            textAlign: 'center',
          }}
        >
          Documentação da API Store
        </CardTitle>
        <TextDocument style={{ textAlign: 'justify' }}>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsum
          reprehenderit, tempore consequatur dolores natus vero assumenda
          recusandae iure veniam, excepturi voluptas similique sequi autem,
          reiciendis earum accusantium! Laudantium, magni aliquid.
        </TextDocument>
        <TextDocument style={{ textAlign: 'justify' }}>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsum
          reprehenderit, tempore consequatur dolores natus vero assumenda
          recusandae iure veniam, excepturi voluptas similique sequi autem,
          reiciendis earum accusantium! Laudantium, magni aliquid.
        </TextDocument>
        <TextDocument style={{ textAlign: 'justify' }}>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsum
          reprehenderit, tempore consequatur dolores natus vero assumenda
          recusandae iure veniam, excepturi voluptas similique sequi autem,
          reiciendis earum accusantium! Laudantium, magni aliquid.
        </TextDocument>
        <TextDocument style={{ textAlign: 'justify' }}>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsum
          reprehenderit, tempore consequatur dolores natus vero assumenda
          recusandae iure veniam, excepturi voluptas similique sequi autem,
          reiciendis earum accusantium! Laudantium, magni aliquid.
        </TextDocument>
        <TextDocument style={{ textAlign: 'justify' }}>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsum
          reprehenderit, tempore consequatur dolores natus vero assumenda
          recusandae iure veniam, excepturi voluptas similique sequi autem,
          reiciendis earum accusantium! Laudantium, magni aliquid.
        </TextDocument>
        <TextDocument style={{ textAlign: 'justify' }}>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsum
          reprehenderit, tempore consequatur dolores natus vero assumenda
          recusandae iure veniam, excepturi voluptas similique sequi autem,
          reiciendis earum accusantium! Laudantium, magni aliquid.
        </TextDocument>
      </CardWrapper>
    </DocumentContainer>
  );
};

export default Documentacao;
