import React from "react";
import AccessFee from "./components/AccessFee";
import AnalyticalMatch from "./components/AnalyticalMatch";
import BubbleChart from "./components/BubbleChart";
import CheckEmail from "./components/CheckEmail";
import CoefficientGraph from "./components/CoefficientGraph";
import Doubts from "./components/Doubts";
import ExactTerms from "./components/ExactTerms";
import Feedback from "./components/Feedback";
import FilterNotification from "./components/FilterNotification";
import HowDoRegister from "./components/HowDoRegister";
import Indicators from "./components/Indicators";
import Integrations from "./components/Integrations";
import OpportunityHistory from "./components/OpportunityHistory";
import PassRecovery from "./components/PassRecovery";
import SearchField from "./components/SearchField";
import Modal from "../../components/common-components/Modal/Modal";
import { eventCategory, logEvent } from "../../util/analytics";
import styled from "styled-components";
import FamilySuggestion from "./components/FamilySuggestion";
import Marketplace from "./components/Marketplace";
import MarketplaceFilter from "./components/MarketplaceFilter";
import MarketplaceRequisitionScreen from "./components/MarketplaceRequisitionScreen";
import MarketplaceSendProposal from "./components/MarketplaceSendProposal";
import MarketplaceRequestDecline from "./components/MarketplaceRequestDecline";
import MarketplaceOpportunityStatusCaption from "./components/MarketplaceOpportunityStatusCaption";
import CardFour from "../Auth/components/CarouselLogin/CardFour";
import MFAAutenticator from "./components/MFAAutenticator";

export const openImage = (isModal:boolean,image: string,closeModal: () => void) => {
  const Image = styled.img`
  width: 100%;
`;

  return (
    isModal && 
    <Modal
    width={'100%'}
    onBlur={() => {
        logEvent(
            eventCategory.modalOpen,
            'click modal open image',
            `click modal open image`
        );
    }} 
    closeModal={() => closeModal()}
    overlay
>
<Image src={image} alt="Imagem" />
</Modal>
  )
}

export const FaqContent = [
  {
    title: "1 - O que é a Minha Petronect?",
    description:
      "A Minha Petronect é uma plataforma de análise estratégica sobre as suas licitações. Uma consultoria digital inteligente que proporciona a gestão das suas atividades a partir das suas movimentações na plataforma."
  },
  {
    title: "2 - Cadastro Minha Petronect",
    description: "",
    subtitle: "2.1 - Como me cadastrar na Minha Petronect?",
    subdescription: [<HowDoRegister />],
    subtitle2: "2.2 - Verificar E-mail",
    subdescription2: [<CheckEmail />],
    subtitle3: "2.3 - Recuperação de senha",
    subdescription3: [<PassRecovery />],
    subtitle4: "2.4 – Autenticação de Dois Fatores (MFA) na Minha Petronect",
    subtitle5: "2.4.1 - O que é o MFA e por que ativá-lo?",
    subdescription5: "A Autenticação de Dois Fatores (MFA) adiciona uma camada extra de segurança ao seu login. Com ela, além da senha, você precisará inserir um código gerado no aplicativo Google Authenticator para acessar sua conta",
    subtitle6: "2.4.2 - Como ativar o MFA na Minha Petronect?",
    subdescription6: <MFAAutenticator />,
  },
  {
    title: "3 - O que é o Match Relevância?",
    description: "É uma aplicação para facilitar a busca e a gestão das oportunidades em Licitações Públicas. A busca é realizada através de palavras-chave e o sistema faz uma leitura em toda descrição, itens e anexos da licitação.",
    subtitle: "3.1 - Campo de Busca",
    subdescription: [<SearchField />],
    subtitle2: "3.2 - Busca de Termos Exatos",
    subdescription2: [<ExactTerms />],
    subtitle3: "3.3 - Gestão e Notificações de Filtros",
    subdescription3: [<FilterNotification />],
  },
  {
    title: "4 - O que é o Meu Desempenho?",
    description: "É o campo de análise de concorrência através das oportunidades encerradas pelo comprador e disponibilizadas para visualização. No painel ficam disponíveis as oportunidades vencedoras, perdidas, desqualificadas e declinadas.",
    subtitle: "4.1 - Indicadores",
    subdescription: [<Indicators />],
    subtitle2: "4.2 - Gráfico de Bolhas",
    subdescription2: [<BubbleChart />],
    subtitle3: "4.3 - Coeficiente Competitividade de Preços",
    subdescription3: [<CoefficientGraph />],
    subtitle4: "4.4 - Histórico de Oportunidades",
    subdescription4: [<OpportunityHistory />],
  },
  {
    title: "5 - O que é o Match Analítico?",
    description: [<AnalyticalMatch />]
  },
//  {
//    title: "6 - O que é o Marketplace?",
//    description: [<Marketplace />],
//    subtitle: "6.1 - Filtros da página Marketplace",
//    subdescription:  [<MarketplaceFilter />],
//    subtitle2: "6.2 - Tela de requisição",
//    subdescription2: [<MarketplaceRequisitionScreen />],
//    subtitle3: "6.2.1 - Enviar proposta",
//    subdescription3: [<MarketplaceSendProposal />],
//    subtitle4: "6.2.2 - Declínio da requisição",
//    subdescription4: [<MarketplaceRequestDecline />],
//    subtitle5: "6.3 - Legenda / Identificação do status das oportunidades",
//    subdescription5: [<MarketplaceOpportunityStatusCaption />],
//  },
  {
    title: "7 - Taxa de Acesso",
    description: [<AccessFee />]
  },
  {
    title: "8 - Sugestão de Famílias",
    description: [<FamilySuggestion />]
  },
  {
    title: "9 - Integrações de CNPJ’s",
    description: "As integrações são utilizadas para que a Minha Petronect receba as informações do perfil do Portal Petronect e com isso disponibilize seu CNPJ para acessar as funcionalidades do Meu Desempenho, Match Analítico e Taxa de Acesso.",
    subtitle: "9.1 - Como realizar a integração de CNPJ",
    subdescription:  [<Integrations />],
  },  
  {
    title: "10 - Feedback",
    description: [<Feedback />]
  },
  {
    title: "11 - Ainda possui dúvidas?",
    description: [<Doubts />]
  }
];
