import React from 'react';

const BeneficiosApiStore = () => {
  return (
    <>
      <p>
        <strong>Benefícios da API Store da Petronect:</strong>
      </p>
      <ul>
        <li>
          Facilidade de Integração: Nossas APIs permitem que você conecte seus
          sistemas aos serviços da Petronect de maneira simples e rápida, sem
          complicações.
        </li>
        <li>
          Visualização de Dados: Através das APIs, você pode acessar e
          visualizar dados importantes para o seu negócio, ajudando na tomada de
          decisões informadas.
        </li>
        <li>
          Inovação e Agilidade: Com a API Store, você pode desenvolver novas
          soluções e melhorar processos existentes, promovendo a inovação e a
          agilidade nos seus negócios.
        </li>
        <li>
          Segurança e Confiabilidade: Nossas APIs são projetadas com foco na
          segurança, garantindo que seus dados estejam sempre protegidos.
        </li>
      </ul>
    </>
  );
};

export default BeneficiosApiStore;
