import React, { FC } from "react";
import Logo from "../../../../components/layout-components/Logo/Logo";
import styled from "styled-components";
import background from "./card2.png"
import Colors from "../../../../assets/styles/colors";

const SlideCardLiveContainer = styled.div`
width: 85%;
height: 100vh;
margin: auto;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
background-image: url(${background});
background-position: center;
background-repeat: no-repeat;
background-size: 100%;
flex-wrap: nowrap;
`;

const LogoContainer = styled.div`
display: flex;
flex-direction: row;
justify-content: space-between;
width:30%;
min-width: 30%;
padding-bottom: 0px;
`

const BodyContainer = styled.div`
width: 90%;
 border:solid 0px;
 box-shadow: 0px 0px 15px -5px  ${Colors.black};
 background-color: ${Colors.white};
 padding-top:20px;
 padding-bottom:20px;
`
const Text = styled.div`
margin: 5px;
text-align: center;
font-family: "Roboto", sans-serif;
font-size: 14px;
letter-spacing: 0px;
color: ${Colors.blue10};
`;

const TextSpan = styled.span`
text-align: center;
color: ${Colors.blue10};
letter-spacing: 0px;
font: 700 14px  sans-serif;

@media (max-width: 1200px) {
  font-size: 1rem;
}
`;

const CardTwo: FC = () => {
    return (
        <SlideCardLiveContainer >
            <LogoContainer>
                <Logo width="30%" widthMobile="20%" />
            </LogoContainer>
            <BodyContainer>
                
                <Text>
                    {`A Minha Petronect é uma plataforma de análise estratégica sobre as licitações disponíveis no Portal Petronect. `}<TextSpan>{`Uma consultoria digital inteligente`}</TextSpan>{` que proporciona a gestão das atividades do Fornecedor a partir das suas movimentações na plataforma.`}
                </Text>
                <Text>
                    {`A Minha Petronect não substitui o Portal Petronect.`}
                </Text>
                <Text>
                    {`É um novo produto criado pela Petronect. Uma plataforma de análise das informações.`}
                </Text>
            </BodyContainer>
        </SlideCardLiveContainer>
    )
}

export default CardTwo;