/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useEffect, useState } from "react";
import { connect } from "react-redux";
import crypto from "crypto";
import { Modal, Button, Accordion } from "react-bootstrap";
import { getMatchOpportunitiesThunk } from "../../dataflow/thunks/opportunities-thunk";
import {
  getUserTermsAcceptedByEmail,
  updateUserTermsAccepted,
} from "../../dataflow/thunks/validate-terms-accepted-thunk";
import { getIntegrationProfilesFromAPI } from "../../dataflow/thunks/integration-profiles";
import exportData from "../../dataflow/thunks/export-data";
import {
  FlexDiv,
  TitleCount,
  ButtonExportData,
  Content,
  Message,
  TermsText,
  Terms,
} from "./styles";
import RelevancyMatchFilter from "./RelevancyMatchFilter";
import ListContent from "./ListContent";
import PDF from "../Auth/components/PDFTerms";
import { ReactComponent as DownloadSvg } from "../../assets/icons/common/download.svg";
import PageHeaderDescription from "../../components/common-components/PageHeaderDescription/PageHeaderDescription";
import { formatDateWithoutTimezone } from "../../util/format-value";
import Colors from "../../assets/styles/colors";
import { eventCategory, logEvent } from "../../util/analytics/index";
import ModalNewVersion from "./ModalNewVersion/index";
import { validateDayMonth } from "../../util/devUtilities";
import ModalFeedback from "./ModalFeedback";
import AWS from "aws-sdk";
import { checkMfaStatus } from "../Auth/Cognito";



interface HomeProps {
  getIntegrationProfilesFromAPI: () => void;
  opportunitiesAPI: any;
  error: any;
  isModalSupport: any;
}

const Home: FC<HomeProps> = ({
  getIntegrationProfilesFromAPI,
  opportunitiesAPI,
  error,
  isModalSupport
}) => {
  const [isHovering, setIsHovering] = useState(false);
  const [isOpenTerms, setIsOpenTerms] = useState(false);
  const [isTermsAccepted, setTermsAccepted] = useState<boolean>();
  const [showAlert, setShowAlert] = useState(false);
  const [termsMessage, setTermsMessage] = useState("");
  const appVersion = (window as any).appVersion;
  const [isModalNewVersion, setIsModalNewVersion] = useState(false);
  const [isModalFeedbacks, setIsModalFeedbacks] = useState(false);
  const oldVersion = window.localStorage.getItem("app_version") ? JSON.parse(window.localStorage.getItem("app_version") || "") : "";
  const readFeedback = window.localStorage.getItem("feedback_modal") ? JSON.parse(window.localStorage.getItem("feedback_modal") || "") : "";


  useEffect(() => {
    getIntegrationProfilesFromAPI();
    checkUserTermsAccepted();
    if (appVersion?.number !== oldVersion?.number) {
      setIsModalNewVersion(true)
    }
    if (validateDayMonth(1, 5) && appVersion?.number === oldVersion?.number && !readFeedback?.read) {
      setIsModalFeedbacks(true)
    }
    if (validateDayMonth(6, 28)) {
      window?.localStorage?.setItem(
        "feedback_modal",
        JSON.stringify({ read: false })
      )
    }
    
  }, []);

  useEffect(() => {
    const verifyMfa = async () => {
      const credentials = localStorage.getItem("petronect_credentials");
    
      if (!credentials) {
        console.error("Credenciais não encontradas no localStorage.");
        return;
      }
    
      try {
        const parsedCredentials = JSON.parse(credentials);
        const accessToken = parsedCredentials?.accessToken;
    
        if (!accessToken) {
          console.error("AccessToken não encontrado dentro de petronect_credentials.");
          return;
        }
    
        const mfaEnabled = await checkMfaStatus(accessToken);
        if (!mfaEnabled) {
          setIsModalNewVersion(true);
        }
      } catch (error) {
        console.error("Erro ao analisar petronect_credentials:", error);
      }
    };

    verifyMfa();
  }, []);

  const handleHover = (item: any) => {
    setIsHovering(item);
  };

  const handleOnHover = () => {
    setIsHovering(false);
  };

  const checkUserTermsAccepted = async () => {
    const response = await getUserTermsAcceptedByEmail();
    setTermsAccepted(response);
    setIsOpenTerms(!response);
  };

  const handleDownloadFile = async (listAPI: any, fileName: any, fileType: any) => {
    const normalizeScore = (score: any) => {
      if (score <= 1) {
        return 1 + "%";
      } else if (score < 100) {
        return Math.round(100 - 100 / score) + "%";
      } else {
        return 100 + "%";
      }
    };

    const data = listAPI.map((item: any) => {
      const dateStart = new Date(item.START_DATE);
      const dateEnd = new Date(item.END_DATE);

      const result = {
        Fit: `${normalizeScore(item.score)}`,
        Categoria: item.COMPANY_DESC,
        Id: item.OPPORT_NUM,
        "Título e Descrição": item.DESC_OBJ_CONTRAT,
        "Data Início": formatDateWithoutTimezone(dateStart, "dd/MM/yyyy"),
        "Data Final": formatDateWithoutTimezone(dateEnd, "dd/MM/yyyy"),
      };

      return result;
    });

    await exportData(data, fileName, fileType);
    const anchor = document.createElement("a");
    document.body.appendChild(anchor);
    const file = `${process.env.REACT_APP_API}/export-data/download/${fileName}.${fileType}`;

    const { idToken } = JSON.parse(window.localStorage.getItem("petronect_credentials") || '');

    const headers = new Headers();
    headers.append("Authorization", `Barear ${idToken}`);

    await fetch(file, { headers })
      .then((response) => response.blob())
      .then((blobby) => {
        const objectUrl = window.URL.createObjectURL(blobby);

        anchor.href = objectUrl;
        anchor.download = fileName;
        anchor.click();

        window.URL.revokeObjectURL(objectUrl);
      });
  };

  const handleCloseModal = async () => {
    setIsOpenTerms(false);
    setTermsAccepted(true);
    const message = await updateUserTermsAccepted(true);

    setTermsMessage(message || '');
    setShowAlert(true);
  };

  const renderListItem = () => {
    const listAPI = opportunitiesAPI;

    const fileName = crypto
      .createHash("md5")
      .update("ABCDEFGHIJKLMNOPQRSTUVWXYZ")
      .digest("hex");

    return (
      <div>
        <FlexDiv>
          <TitleCount>{listAPI.length} licitações encontrada(s).</TitleCount>
          <ButtonExportData
            onClick={() => {
              logEvent(
                eventCategory.buttonClick,
                "click Exportar CSV",
                "Exportar CSV"
              );
              handleDownloadFile(listAPI, fileName, "csv");
            }}
          >
            <span>Exportar CSV</span>
            <DownloadSvg />
          </ButtonExportData>
          <ButtonExportData
            onClick={() => {
              logEvent(
                eventCategory.buttonClick,
                "click Exportar Excel",
                "Exportar Excel"
              );
              handleDownloadFile(listAPI, fileName, "xlsx");
            }}
          >
            <span>Exportar Excel</span>
            <DownloadSvg />
          </ButtonExportData>
        </FlexDiv>
        <ListContent
          handleHover={() => handleHover}
          handlenoHover={() => handleOnHover}
          list={listAPI}
          isHovering={isHovering}
        />
      </div>
    );
  };

  const renderModalAlert = () => {
    return (
      <Modal show={true} onHide={() => setShowAlert(false)} animation={true}>
        <Modal.Header closeButton />
        <Modal.Body>
          <h3>{termsMessage}</h3>
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant="primary"
            style={{ backgroundColor: Colors.blue8, border: "none" }}
            onClick={() => setShowAlert(false)}
          >
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const listAPI = opportunitiesAPI;

  return (
    <>
      <Content>
        <PageHeaderDescription
          title={"Match Relevância"}
          paragraphs={[
            "É uma busca avançada em Licitações Públicas. A busca é realizada através de palavras-chave disponibilizadas e o sistema faz uma leitura em toda descrição, itens e anexos da licitação. É possível receber uma notificação diária por e-mail, das licitações disponíveis baseadas no seu filtro salvo.",
          ]}
        />
        <RelevancyMatchFilter />
        {isModalSupport}
        {listAPI.length > 0 ? (
          renderListItem()
        ) : (
          <Message>
            {error !== ""
              ? error
              : "Pesquise por palavras-chave para visualizar oportunidades"}
          </Message>
        )}
      </Content>
      {isOpenTerms && !isTermsAccepted && (
        <Modal
          show={true}
          onHide={handleCloseModal}
          backdrop="static"
          animation={false}
          size="lg"
          centered
        >
          <Modal.Header>
            <Modal.Title>Termos de uso</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <TermsText>
              Houve mudança nos nossos <strong>Termos de Uso</strong>. Clique no
              botão abaixo para concordar.
            </TermsText>
            <Accordion>
              <Accordion.Item style={{ outline: "none" }} eventKey="0">
                <Accordion.Header>Termos de uso</Accordion.Header>
                <Accordion.Body>
                  <Terms className="PDF">
                    <PDF style={{ border: "1px solid red" }} />
                  </Terms>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="primary"
              style={{ backgroundColor: Colors.blue8, border: "none" }}
              onClick={handleCloseModal}
            >
              Li e aceito os termos de uso
            </Button>
          </Modal.Footer>
        </Modal>
      )}
      {showAlert && renderModalAlert()}
      {isModalNewVersion && (
        <ModalNewVersion
          closeModal={() => { setIsModalNewVersion(false) }}
        />
      )}
      {isModalFeedbacks && (
        <ModalFeedback
          closeModal={() => { setIsModalFeedbacks(false) }}
        />
      )}
    </>
  );
};

const mapStateToProps = (state: any) => ({
  auth: state.auth,
  integrationProfiles: state.integrationProfiles.integrationProfiles,
  opportunities: state.opportunities.matchOpportunities,
  opportunitiesAPI: state.opportunities.apiOpportunities,
  error: state.opportunities.error,
});

const mapDispatchToProps = (dispatch: any) => ({
  getIntegrationProfilesFromAPI: () => {
    dispatch(getIntegrationProfilesFromAPI());
  },
  getMatchOpportunitiesThunk: (info: any) => {
    dispatch(getMatchOpportunitiesThunk(info));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);
