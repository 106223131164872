import { toast } from 'react-toastify';
import jwt_decode from 'jwt-decode';
import defineCookie from '../../util/defineCookies';

interface FormData {
  nome: string;
  sobrenome: string;
  email: string;
  telefone: string;
  cpf: string;
  cnpj: string;
  aceite: boolean;
}

const SendEmailApiStore = async ({
  nome,
  sobrenome,
  email,
  telefone,
  cpf,
  cnpj,
  aceite,
}: FormData): Promise<void> => {
  const { idToken } = JSON.parse(
    window?.localStorage?.getItem('petronect_credentials') || '',
  );
  const decodedToken: any = jwt_decode(idToken);
  const cognito = decodedToken['cognito:username'];

  const apiUrl =
    'https://f1rtw0ffza.execute-api.us-east-1.amazonaws.com/prod/mpne';
  const body = {
    nome: nome + ' ' + sobrenome,
    email: email,
    telefone: telefone,
    cpf: cpf,
    cnpj: cnpj,
    cognito: cognito,
    aceite: aceite ? 'Sim' : 'Não',
  };

  try {
    await fetch(apiUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
      mode: 'no-cors',
    });

    defineCookie({
      key: 'ApiStore',
      value: 'true',
      days: 3,
      secure: true,
      sameSite: 'Lax',
    });
    toast.success(
      `Ficha enviada com sucesso! Sua participação está em análise.`,
    );
  } catch (error) {
    console.error('Erro ao enviar requisição:', error);
    toast.error('Houve um problema ao processar sua solicitação.');
  }
};

export default SendEmailApiStore;
