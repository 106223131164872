import React from 'react';
import Modal from '../../components/common-components/Modal/Modal';
import { eventCategory, logEvent } from '../../util/analytics';
import styled from 'styled-components';
import BeneficiosApiStore from './components/BeneficiosApiStore';
import ServicosOferecidos from './components/ServicosOferecidos';

export const openImage = (
  isModal: boolean,
  image: string,
  closeModal: () => void,
) => {
  const Image = styled.img`
    width: 100%;
  `;

  return (
    isModal && (
      <Modal
        width={'100%'}
        onBlur={() => {
          logEvent(
            eventCategory.modalOpen,
            'click modal open image',
            `click modal open image`,
          );
        }}
        closeModal={() => closeModal()}
        overlay
      >
        <Image src={image} alt="Imagem" />
      </Modal>
    )
  );
};

export const FaqParticipeApiStoreContent = [
  {
    title: '1 - O que é a API Store?',
    description:
      'A API Store da Petronect é uma plataforma que oferece uma ampla variedade de APIs (Interfaces de Programação de Aplicações) para facilitar a integração e a visualização de dados. Mas o que são APIs? APIs são conjuntos de regras e definições que permitem que diferentes sistemas de software se comuniquem entre si. Pense nelas como pontes que conectam diferentes aplicações, permitindo a troca de informações de forma eficiente e segura.',
    subdescription: <BeneficiosApiStore />,
  },
  {
    title: '2 - Serviços oferecidos',
    description:
      'A API Store da Petronect oferece diversas APIs, cada uma com funcionalidades específicas para atender às suas necessidades. Aqui estão algumas das principais:',
    subdescription: <ServicosOferecidos />,
  },
  {
    title: '3 - Como participar da API Store?',
    description:
      'Para participar da API Store, basta preencher o formulário a baixo e aguardar a aprovação interna para a liberação de acesso.',
  },
];
