import React, { FC, useRef, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import jwt_decode from "jwt-decode";
import "react-toastify/dist/ReactToastify.css";
import {
  AltBox,
  Close,
  ContainerOpacity,
  ContentDiv,
  HighlightText,
  IconInputPassword,
  InputBox,
  LeftSideContainer,
  Link,
  Loader,
  LoaderContainer,
  LoginForm,
  LoginMessageError,
  ModalBox,
  NewUserDiv,
  TextError,
  TextVersion,
  TextWarning,
  TourText,
  TourTextLink,
} from "./styles";
import { Button } from "../../../components";
import Logo from "../../../components/layout-components/Logo/Logo";
import Faq from "../../Faq/Faq";
import { loginUser } from "../Cognito";
import hidePasswordIcon from "../../../assets/icons/common/login-hide-password.svg";
import showPasswordIcon from "../../../assets/icons/common/login-show-password.svg";
import loader from "../../../assets/icons/common/loading.svg";
import RightSideContent from "./RightSideContent";
import UnformInput from "../../../components/common-components/UnformInputs/UnformInput";
import Colors from "../../../assets/styles/colors";
import { eventCategory, logEvent } from "../../../util/analytics/index";
import { checkDeviceMobile } from "../../../util/devUtilities";
import { CognitoIdentityServiceProvider }  from "aws-sdk";
import * as AWS from 'aws-sdk';
import ModalMFA from "../../../components/common-components/ModalMFA";
import { CognitoUser, CognitoUserSession, AuthenticationDetails, CognitoUserPool } from 'amazon-cognito-identity-js';
import jwtDecode from "jwt-decode";


interface LoginProps {
  history: any;
  handlePasswordReset: () => void;
  handleCreate: () => void;
  handleRecoverPassword: () => void;
  handleResendEmailForm: () => void;
  error: any;
}

interface SessionData {
  session: string;
  username: string;
  accessToken?: string;
  idToken?: string;
  refreshToken?: string;
  vendorId?: string;
  userId?: number;
}

const Login: FC<LoginProps> = ({
  history,
  handlePasswordReset,
  handleCreate,
  handleRecoverPassword,
  handleResendEmailForm,
  error
}) => {
  const appVersion = (window as any).appVersion;
  const [isToastShowed, setisToastShowed] = useState(false);
  const [messageModal, setMessageModal] = useState(false);
  const [showPassword, setShowPassword] = useState(true);
  const [isShowingFaq, setIsShowingFaq] = useState(false);
  const [_error, set_Error] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [mfaModalVisible, setMfaModalVisible] = useState(false);
  const [sessionData, setSessionData] = useState<SessionData | null>(null);
  const _history = useHistory();
  const formRef: any = useRef(null);
  const isMobile:boolean = checkDeviceMobile();

  const getVerifiedEmailFromURL = () => {
    if (_history && _history.location.search !== "" && !isToastShowed) {
      buildToast(
        `O e-mail ${_history.location.search.replace(
          "?verifiedEmail=",
          ""
        )} foi verificado com sucesso, use-o para logar-se`
      );
    }
  };

  const buildToast = (phrase: any) => {
    toast.success(phrase, {
      position: "top-left",
      autoClose: 10000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    setisToastShowed(true);
  };

  const cognito = new CognitoIdentityServiceProvider({
    region: process.env.AWS_COGNITO_REGION || 'us-east-1',
  });

  
  const handleLogin = async (data: any) => {
    setIsLoading(true);
    logEvent(eventCategory.buttonClick, "login na aplicação", "Entrar");

    const username = data.username.trim();
    const password = data.password.trim();

    try {
      const params = {
        AuthFlow: "USER_PASSWORD_AUTH",
        ClientId: process.env.REACT_APP_COGNITO_CLIENT_ID!,
        AuthParameters: {
          USERNAME: username,
          PASSWORD: password,
        },
      };
  
      const response = await cognito.initiateAuth(params).promise();
  
      if (response.ChallengeName === "SOFTWARE_TOKEN_MFA") {
        setSessionData({
          session: response.Session || "",
          username,
        });
        setIsLoading(false);
        setMfaModalVisible(true);
        return;
      }
  
      const { AuthenticationResult } = response;
  
      if (AuthenticationResult) {
        const { IdToken, AccessToken, RefreshToken } = AuthenticationResult;
  
        if (!IdToken) throw new Error("ID Token não encontrado.");
  
        const decodedIdToken: any = jwt_decode(IdToken);
  
        const userData = {
          accessToken: AccessToken,
          idToken: IdToken,
          refreshToken: RefreshToken,
          vendorId: decodedIdToken.id_vendor,
          userId: Number(decodedIdToken.id_user),
          username: decodedIdToken.username,
          mfaVerified: false,
        };
  
        window.localStorage.setItem("petronect_credentials", JSON.stringify(userData));
  
        setSessionData({
          session: response.Session || "",
          ...userData,
        });
  
        setMessageModal(true);
        setIsLoading(false);
  
        _history.push("/match-relevancia");
      } else {
        throw new Error("AuthenticationResult não encontrado.");
      }
    } catch (err: any) {
      setIsLoading(false);
      console.error("Erro ao fazer login:", err);
  
      if (err.code === "LimitExceededException") {
        set_Error("Limite de requisições excedido");
      } else if (err.code === "UserNotConfirmedException") {
        handleResendEmailForm();
        set_Error("Confirme seu email antes de efetuar o login");
      } else if (err.code === "NotAuthorizedException" || err.code === "UserNotFoundException") {
        set_Error("Email ou senha inválidos");
      } else {
        console.error("Erro desconhecido:", err);
        set_Error("Erro ao fazer login");
      }
    }
  };
  
  const handleMfaConfirmation = async (mfaCode: string) => {
    const { session, username } = sessionData || {};
  
    if (!session || !username) {
      alert("Sessão ou nome de usuário não encontrados.");
      return;
    }
  
    try {
      const params = {
        ChallengeName: "SOFTWARE_TOKEN_MFA",
        ClientId: process.env.REACT_APP_COGNITO_CLIENT_ID!,
        Session: session,
        ChallengeResponses: {
          USERNAME: username,
          SOFTWARE_TOKEN_MFA_CODE: mfaCode, 
        },
      };
  
      const response = await cognito.respondToAuthChallenge(params).promise();
  
      if (response.AuthenticationResult) {
        const { IdToken, AccessToken, RefreshToken } = response.AuthenticationResult;
  
        if (!IdToken) {
          console.error("Erro: ID Token não encontrado após MFA.");
          alert("Erro ao concluir a autenticação.");
          return;
        }
  
        const decodedIdToken: any = jwtDecode(IdToken);
  
        window.localStorage.setItem(
          "petronect_credentials",
          JSON.stringify({
            accessToken: AccessToken,
            idToken: IdToken,
            refreshToken: RefreshToken,
            vendorId: decodedIdToken.id_vendor,
            userId: Number(decodedIdToken.id_user),
            mfaVerified: true, 
          })
        );
  
        setSessionData({
          session: "",
          accessToken: AccessToken,
          idToken: IdToken,
          refreshToken: RefreshToken,
          username: decodedIdToken.username,
          vendorId: decodedIdToken.id_vendor,
          userId: Number(decodedIdToken.id_user),
        });
  
        console.log("Autenticação MFA concluída. Redirecionando...");
        setMfaModalVisible(false);
  
        _history.push("/match-relevancia");
      } else {
        console.error("Nenhum AuthenticationResult retornado após MFA.");
        alert("Erro ao finalizar login. Tente novamente.");
      }
    } catch (err) {
      console.error("Erro ao validar código MFA:", err);
      alert("Erro ao validar o código MFA.");
      setMfaModalVisible(false);
    }
  };
  
  
  
  
  
  

  const renderError = () => {
    if (_error && !isLoading) {
      return (
        <LoginMessageError>
          <TextError>{_error}</TextError>
        </LoginMessageError>
      );
    }
    return null;
  };

  const renderButton = () => {
    if (isLoading) {
      return (
        <LoaderContainer>
          <Loader src={loader} />
        </LoaderContainer>
      );
    }

    return (
      <Button
        margin=".25rem 0px 2rem 0px"
        width="65%"
        widthMobile="100%"
        height="3.5rem"
        background={Colors.blue8}
      >
        Entrar
      </Button>
    );
  };

  const messageModalContent = () =>
    messageModal && (
      <ContainerOpacity>
        <ModalBox>
          <Close onClick={() => setMessageModal((prevState) => !prevState)}>
            X
          </Close>
          <TextWarning>
            Antes de efetuar o login é preciso confirmar seu endereço de email
          </TextWarning>
        </ModalBox>
      </ContainerOpacity>
    );
  const isInMaintenance = false;
  if (isInMaintenance) {
    window.localStorage.removeItem("petronect_credentials");
  }
  // const mapStateToProps = (state) => ({
  //   error: state.auth.error,
  //   createSuccess: state.auth.createSuccess,
  // });

  if (history.location.search.includes("code")) {
    history.push({
      pathname: "/login",
      search: "",
    });
    handlePasswordReset();
  }

  return (
    <>
      {getVerifiedEmailFromURL()}
      <ToastContainer
        position="top-left"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <LeftSideContainer>
        {isShowingFaq && (
          <>
            <Faq />
            <TourText>
              <span
                onClick={() => setIsShowingFaq(false)}
                style={{
                  fontWeight: "bold",
                  cursor: "pointer",
                  textDecoration: "underline",
                }}
              >
                Voltar para a tela inicial
              </span>
            </TourText>
          </>
        )}
        {!isShowingFaq && (
          <ContentDiv>
            {messageModalContent()}
            <Logo width="35%" height="auto" marginTop="1rem" />
            <HighlightText>
                {`Mais agilidade e eficiência para o seu negócio`}
            </HighlightText>
            <NewUserDiv>
              <h5>Seu primeiro acesso?</h5>
              <Button
                widthMobile={isMobile ? "85%" : "100%"}
                height="3rem"
                background={Colors.blue8}
                onClick={handleCreate}
              >
                Criar conta
              </Button>
            </NewUserDiv>
            <LoginForm onSubmit={handleLogin} ref={formRef} placeholder="Criar conta">
              <InputBox width="65%">
                <UnformInput
                  name={"username"}
                  required
                  autoFocus
                  type="email"
                  placeholder={"nome@email.com"}
                  error={error}
                  labelText="Email"
                />
              </InputBox>
              <InputBox last lastResponsive width="65%">
                <UnformInput
                  name={"password"}
                  type={showPassword ? "password" : "text"}
                  required
                  placeholder={"senha"}
                  error={error}
                  labelText="Senha"
                  onSubmit={() => {
                    formRef?.current?.submit();
                  }}
                />
                <IconInputPassword
                  src={showPasswordIcon || hidePasswordIcon}
                  onClick={() => {
                    setShowPassword((prevState) => !prevState);
                  }}
                />
              </InputBox>
              {renderError()}
              <AltBox>
                <Link onClick={handleRecoverPassword}>
                  Recuperar Senha
                </Link>
                <Link onClick={handleResendEmailForm}>Verificar Email</Link>
              </AltBox>
              {renderButton()}
            </LoginForm>
          </ContentDiv>
        )}
        {messageModalContent()}
        {!isShowingFaq && (
          <TourTextLink
          to={"/faq"}
            style={{ cursor: "pointer" }}
            onClick={() => {
              // setIsShowingFaq(true)
              logEvent(
                eventCategory.linkClick,
                'FAQ',
                'Dúvidas? Clique aqui para acessar o nosso FAQ'
              );
            }}
          >
            <strong>Dúvidas? </strong>
            <span>
              <u>Clique aqui</u>
            </span>
            para acessar o nosso FAQ
          </TourTextLink>
        )}
        <TextVersion>{appVersion.number}</TextVersion>

        {mfaModalVisible && (
      <ModalMFA
        title="Autenticação de Dois Fatores"
        callbackCloseModal={() => setMfaModalVisible(false)}
        callbackConfirm={handleMfaConfirmation}
        confirmText="Confirmar"
      >
        Para continuar, insira o código MFA gerado pelo seu aplicativo de autenticação.
      </ModalMFA>
    )}
      </LeftSideContainer>
      <RightSideContent />
    </>
  );
};

const mapStateToProps = (state: any) => ({
  error: state.auth.error,
  createSuccess: state.auth.createSuccess,
});

export default connect(mapStateToProps)(Login);
