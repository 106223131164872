import styled from 'styled-components';
import Colors from '../../assets/styles/colors';

interface StylesProps {
  width?: any;
}

export const ContainerPage = styled.div`
  margin-top: 78px;
  width: 100%;
  height: 100%;
  overflow: auto;
  @media only screen and (max-width: 600px) {
    padding-bottom: 60px;
  }
`;

export const Container = styled.div`
  padding: 78px 50px 50px 50px;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 1rem;
  @media (max-width: 500px) {
    padding: 68px 10px 10px 10px;
  }
`;

export const DocumentContainer = styled.div`
  padding: 20px 50px 20px 50px;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 1rem;
  @media (max-width: 500px) {
    padding: 10px 10px 10px 10px;
  }
`;

export const LoaderContainer = styled.div`
  width: 100%;
  height: 70%;
  display: flex; 
  justify-content: center;
  align-items: center;
`;

export const Title = styled.div`
  font-size: 20px;
  color: ${Colors.blue6};
  margin-bottom: 20px;
`;

export const ContentContainer = styled.div`
  display: grid;
  grid-template-columns: auto;
  grid-gap: 20px 20px;

  @media (max-width: 768px) {
    grid-template-columns: auto;
  }

  @media only screen and (max-width: 600px) {
    grid-template-columns: auto;
  }
`;

export const Image = styled.img`
  width: ${(props: StylesProps) => props.width || '90%'};
`;

export const CardTitle = styled.h2`
  font-size: 20px;
  display: flex;
  color: ${Colors.blue6};
  margin-bottom: 20px;
  font-weight: bold;
`;

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  max-width: 600px;
  margin: 0 auto;
  border: 3px solid ${Colors.blue6};
`;

export const CardWrapper = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const ParticipationFormWrapper = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 400px;
  margin-bottom: 20px;

  h2 {
    text-align: center;
    margin-bottom: 20px;
  }
`;

export const InputGroup = styled.div`
  margin-bottom: 15px;
  width: 100%;

  label {
    font-weight: bold;
    margin-bottom: 5px;
    display: block;
  }
`;

export const InputField = styled.input`
  padding: 10px;
  width: 100%;
  margin-top: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;

  &:focus {
    border-color: #007bff;
  }
`;

export const CheckboxInput = styled.input`
  margin-right: 10px;
  font-size: 14px;
  display: inline;

  &:focus {
    border-color: #007bff;
    cursor: pointer;
  }
`;

export const CheckboxGroup = styled.div`
  margin-bottom: 15px;
  width: 100%;
  align-items: center;

  label {
    font-weight: bold;
    cursor: pointer;
    display: inline;
  }
`;

export const SubmitButton = styled.button`
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #0056b3;
  }
`;

export const BackLink = styled.div`
  font: Medium 14px/18px Roboto;
  text-align: center;
  max-width: 500px;
  margin: 10px auto;
  letter-spacing: 0;
  font-size: 20px;
  color: ${Colors.blue6};
  cursor: pointer;

  span {
    font-weight: bold;
    text-decoration: underline;
  }

  &:hover {
    color: #007bff;
  }
`;

export const Error = styled.p`
  color: red;
`;

export const Text = styled.div`
  text-align: justify;
`;

export const TextDocument = styled.p``;

export const Loader = styled.img`
  width: 80px;
  height: 80px;
`;
