import { Redirect, Route, Switch } from 'react-router-dom';
import Onboarding from '../pages/Auth/Onboarding';
import React, { Suspense } from 'react';
import Private from './private';
import MatchRelevance from '../pages/MatchRelevance';
import MatchFilters from '../pages/MatchFilters/MatchFilters';
import Help from '../pages/Help/Help';
import Support from '../components/common-components/Support/Support';
import Dashboard from '../pages/MyPerformance';
import EasyProposalScreenManager from '../components/modules-components/match-analytical/EasyProposalScreenManager';
import ProposalDescription from '../pages/ProposalDescription/ProposalDescription';
import IntegrationProfilesManager from '../pages/Integration/ManagerProfileScreens';
import FaqPage from '../pages/Faq/FaqPage';
import Maintenance from '../pages/Maintenance/Maintenance';
import ComingSoon from '../pages/ComingSoon';
import AccessFee from '../pages/AccessFee';
import UploadMembershipList from '../pages/UploadMembershipList';
import UploadFamilyList from '../pages/UploadFamilyList';
import FamilySuggestion from '../pages/FamilySuggestion';
import FamilyDescription from '../pages/FamilyDescription/FamilyDescription';
import MarketplaceScreenManager from '../components/modules-components/marketplace/MarketplaceScreenManager';
import MarketplaceDescription from '../pages/Marketplace/pages/MarketplaceDescription';
import MFACodePage from '../pages/MFA/MFACodePage';
import ApiStorePage from '../pages/ApiStore/ApiStorePage';
import ParticipeApiStorePage from '../pages/ParticipeApiStore/ParticipeApiStorePage';

const Routes = () => {
  // const history = useHistory();
  // const search = history.location.search;
  // const regex = /^\?homologkey=(.*)$/;
  // let isValidHomolog = false;
  // if (regex.exec(search)) {
  //   const key = regex.exec(search)[1];
  //   isValidHomolog = key === process.env.REACT_APP_HOMOLOG_KEY;
  // }

  // const renderFeatures =
  //   isValidHomolog || process.env.NODE_ENV === "development";

  return (
    <Suspense fallback={<div />}>
      <Switch>
        <Route
          exact
          path="/pne-access"
          render={(props) => <Onboarding {...props} />}
        />
        <Route
          exact
          path="/login"
          render={(props) => <Onboarding {...props} />}
        />
        <Route
          exact
          path="/mfa"
          render={(props) => <MFACodePage {...props} />}
        />
        <Route
          exact
          path="/faq"
          render={(props: any) => <FaqPage {...props} />}
        />
        <Private path="/match-relevancia" component={MatchRelevance} />
        <Private path="/match-filter" component={MatchFilters} />
        <Private path="/help" component={Help} />
        <Private path="/support" component={Support} />
        <Private path="/apistore" component={ApiStorePage} />
        <Private path="/private-faq" component={FaqPage} />
        <Private path="/participeApistore" component={ParticipeApiStorePage} />
        <Private path="/maintenance" component={Maintenance} />
        <Private path="/em-breve/:feature" component={ComingSoon} />
        <Private
          path="/meu-desempenho"
          // component={Maintenance}
          render={(props: any) => <Dashboard {...props} />}
        />

        <Private
          exact
          path="/marketplace"
          render={(props: any) => <MarketplaceScreenManager {...props} />}
        />
        <Private
          exact
          path="/marketplace/:id"
          render={(props: any) => <MarketplaceDescription {...props} />}
        />
        <Private
          exact
          path="/match-analitico"
          render={(props: any) => <EasyProposalScreenManager {...props} />}
        />
        <Private
          path="/match-analitico/:proposalId"
          render={(props: any) => <ProposalDescription {...props} />}
        />
        <Private path="/access-fee" component={AccessFee} />
        <Private
          exact
          path="/integracoes"
          render={(props: any) => <IntegrationProfilesManager {...props} />}
        />
        <Private
          path="/upload-membership-list"
          component={UploadMembershipList}
        />
        <Private path="/upload-family-list" component={UploadFamilyList} />
        <Private path="/family-suggestion" component={FamilySuggestion} />
        <Private
          path="/family-suggestion-description"
          render={(props: any) => <FamilyDescription {...props} />}
        />

        <Private exact path={'/'} />
        <Redirect to="/" />
      </Switch>
    </Suspense>
  );
};

export default Routes;
