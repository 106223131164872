import { useCallback } from 'react';
import AcessoApiStore from '../dataflow/thunks/AcessoApiStore';

interface getCookieProps {
  key: string;
}

const getCookie = ({ key }: getCookieProps): string | null => {
  const cookie = document.cookie
    .split('; ')
    .find((row) => row.startsWith(key + '='));

  return cookie ? decodeURIComponent(cookie.split('=')[1]) : null;
};

const useVerifyParticipe = (): boolean => {
  const { existe } = AcessoApiStore();

  const verifyExistence = useCallback(() => {
    const cookie = getCookie({ key: 'ApiStore' });

    return cookie === 'true' || existe;
  }, [existe]);

  return verifyExistence();
};

export default useVerifyParticipe;
