import React from 'react';
import { CardWrapper, CardTitle, Text } from '../styles';
import ApiStoreLogo from '../../../assets/img/logos/ApiStore.svg';

const AguardeResposta = () => {
  return (
    <CardWrapper>
      <img src={ApiStoreLogo} alt="Logo da ApiStore" width={80} />
      <CardTitle
        style={{
          textAlign: 'center',
        }}
      >
        Sua participação está em análise!{' '}
      </CardTitle>
      <Text style={{ textAlign: 'justify' }}>
        Estamos revisando sua solicitação e retornaremos com uma resposta em até{' '}
        <strong>72 horas úteis</strong>. Fique atento ao seu e-mail para
        atualizações.
      </Text>
    </CardWrapper>
  );
};

export default AguardeResposta;
