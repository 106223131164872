import React, { FC, useState } from 'react';
import { Container, Title, ContentContainer } from './styles';
import { connect } from 'react-redux';
import { sendMessage, changeStatus } from '../../dataflow/thunks/support-thunk';
import { FaqParticipeApiStoreContent } from './content';
import { decodedToken } from '../../util/devUtilities';
import Accordion from 'react-bootstrap/Accordion';

const mapStateToProps = (state: any) => ({
  supportResponse: state.support.supportResponse,
});

const mapDispatchToProps = (dispatch: any) => ({
  sendMessage: (info: any) => dispatch(sendMessage(info)),
  changeStatus: (info: any) => dispatch(changeStatus(info)),
});

const FaqParticipeApiStore: FC = () => {
  const [activeKey, setActiveKey] = useState(null);

  const handleAccordionToggle = (eventKey: any) => {
    setActiveKey(activeKey === eventKey ? null : eventKey);
  };

  return (
    <>
      <Container>
        <Title>{`Olá!${
          true ? '' : `${decodedToken('idToken')?.name}`
        } Conheça e participe da API Store!`}</Title>
        <ContentContainer>
          {FaqParticipeApiStoreContent.map((element: any, index: any) => {
            return (
              <Accordion activeKey={activeKey} onSelect={handleAccordionToggle}>
                <Accordion.Item key={index} eventKey={index.toString()}>
                  <Accordion.Header>
                    <h1
                      style={{
                        fontSize: '16px',
                        fontWeight: '800',
                        fontFamily: 'sans-serif',
                        color: '#116DA0',
                      }}
                    >
                      {element.title}
                    </h1>
                  </Accordion.Header>
                  <Accordion.Body
                    style={{
                      fontFamily: 'sans-serif',
                      fontSize: '14px',
                      color: '#505050',
                    }}
                  >
                    <div>
                      <p>{element?.description}</p>
                      <p
                        style={{
                          fontWeight: '800',
                          fontFamily: 'sans-serif',
                          color: '#116DA0',
                        }}
                      >
                        {element?.subtitle}
                      </p>
                      <p>{element?.subdescription}</p>
                      <p
                        style={{
                          fontWeight: '800',
                          fontFamily: 'sans-serif',
                          color: '#116DA0',
                        }}
                      >
                        {element?.subtitle2}
                      </p>
                      <p>{element?.subdescription2}</p>
                      <p
                        style={{
                          fontWeight: '800',
                          fontFamily: 'sans-serif',
                          color: '#116DA0',
                        }}
                      >
                        {element?.subtitle3}
                      </p>
                      <p>{element?.subdescription3}</p>
                      <p
                        style={{
                          fontWeight: '800',
                          fontFamily: 'sans-serif',
                          color: '#116DA0',
                        }}
                      >
                        {element?.subtitle4}
                      </p>
                      <p>{element?.subdescription4}</p>
                      <p
                        style={{
                          fontWeight: '800',
                          fontFamily: 'sans-serif',
                          color: '#116DA0',
                        }}
                      >
                        {element?.subtitle5}
                      </p>
                      <p>{element?.subdescription5}</p>
                      <p
                        style={{
                          fontWeight: '800',
                          fontFamily: 'sans-serif',
                          color: '#116DA0',
                        }}
                      >
                        {element?.subtitle6}
                      </p>
                      <p>{element?.subdescription6}</p>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            );
          })}
        </ContentContainer>
      </Container>
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(FaqParticipeApiStore);
