export const formatTell = (tell: string | null) => {
  if (tell === null) {
    return '';
  }
  let digitsOnly = tell.replace(/\D/g, '');

  if (digitsOnly.startsWith('55')) {
    digitsOnly = digitsOnly.slice(2);
  }
  // Formatação para celular (11 dígitos)
  if (digitsOnly.length === 11) {
    return `+55 (${digitsOnly.slice(0, 2)}) ${digitsOnly.slice(
      2,
      7,
    )}-${digitsOnly.slice(7)}`;
  }
  // Formatação para fixo (10 dígitos)
  else if (digitsOnly.length === 10) {
    return `+55 (${digitsOnly.slice(0, 2)}) ${digitsOnly.slice(
      2,
      6,
    )}-${digitsOnly.slice(6)}`;
  }
  return tell;
};
