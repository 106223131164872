const DowloadTerms = () => {
  const url = 'Termos-de-Uso-API-Store-Petronect.pdf';
  const link = document.createElement('a');
  link.href = url;
  link.download = 'Termos de Uso API Store Petronect.pdf';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export default DowloadTerms;
