import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { ButtonClose, ButtonConfirm, Container, ContainerButton, Description, Title } from "./styles";



const ModalMFA = ({ 
  title, 
  children, 
  callbackCloseModal, 
  closeText = "Fechar",
  callbackConfirm,
  confirmText
}: any) => {

  const [mfaCode, setMfaCode] = useState("");

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setMfaCode(e.target.value);
  };

  const handleConfirm = () => {
    if (mfaCode.trim() === "") {
      alert("Por favor, insira o código MFA.");
      return;
    }
    if (callbackConfirm) {
      callbackConfirm(mfaCode);
      callbackCloseModal();
    }
  };

  const inputStyle = {
    width: "100%",
    outline: "none",
    margin: "0.5rem 0",
    font: "400 1rem 'Open Sans'",
    border: mfaCode.trim() === "" ? "1px solid #E74C3C" : "1px solid #2ECC71",
    padding: "1.25rem calc(1rem - 2px)",
    color: "#7F8C8D",
    background: "#ECF0F1",
    borderRadius: "4px",
  };

  return (
    <Modal show={true} onHide={callbackCloseModal} animation={true} centered>
      <Modal.Body> 
        <Container> 
          <Title>{title}</Title>
          <Description>{children}</Description>


          <div style={{ display: "flex", width: "100%", flexDirection: "column", alignItems: "flex-start" }}>
            <label 
              htmlFor="mfaCode"
              style={{
                font: "500 0.75rem 'Eurostile', 'Open Sans', sans-serif",
                margin: "0 0 0.25rem 0",
                color: "#2ECC71",
              }}
            >
              Digite o código MFA
            </label>
            <input
              name="number"
              type="text" 
              required
              value={mfaCode}
              onChange={handleInputChange}
              placeholder="Digite o código MFA"
              maxLength={6} 
              style={inputStyle}
            />
          </div>


          <ContainerButton>
            {confirmText && <ButtonConfirm onClick={handleConfirm}>{confirmText}</ButtonConfirm>}
            <ButtonClose onClick={callbackCloseModal}>{closeText}</ButtonClose>
          </ContainerButton>

        </Container>  
      </Modal.Body>
    </Modal>
  );
};

export default ModalMFA;
