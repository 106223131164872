import { useEffect, useState } from 'react';
import jwt_decode from 'jwt-decode';

interface VerifyExistentParams {
  cognito: string;
}

interface UsuarioResponse {
  aprovado: boolean;
  motivo?: string;
  situacao: Situacao;
}

const VerifyExistent = async ({ cognito }: VerifyExistentParams): Promise<UsuarioResponse | null> => {
  const apiUrl = '';
  try {
    const response = await fetch(`${apiUrl}?cognito=${cognito}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    const data = await response.json();

    if (data.exists) {
      return data;
    } else {
      return null;
    }
  } catch (error) {
    console.error('Erro ao verificar Usuário:', error);
    return null;
  }
};

type Situacao = 'aguardando' | 'TaxaDeAcesso' | 'CNPJInvalido';

interface AcessoApiStore {
  aprovado: boolean;
  situacao: Situacao;
  isLoading: boolean;
  existe: boolean;
}

const AcessoApiStore = (): AcessoApiStore => {
  const [aprovado, setAprovado] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [situacao, setSituacao] = useState<Situacao>('aguardando');
  const [existe, setExiste] = useState<boolean>(false);

  const processarUsuario = (usuario: UsuarioResponse) => {
    setExiste(true);
    if (usuario.aprovado) {
      setAprovado(true);
    } else {
      setAprovado(false);
      if (usuario.motivo) {
        setSituacao(usuario.motivo === 'Taxa-de-Acesso' ? 'TaxaDeAcesso' : 'CNPJInvalido');
      }
    }
  };

  const handleError = () => {
    setSituacao('aguardando');
    setAprovado(false);
    setIsLoading(false);
    setExiste(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      const { idToken } = JSON.parse(window?.localStorage?.getItem('petronect_credentials') || '{}');
      setIsLoading(true);

      if (idToken) {
        const decodedToken: any = jwt_decode(idToken);
        const cognito = decodedToken['cognito:username'];

        const usuario = await VerifyExistent({ cognito });

        if (!usuario) {
          handleError();
        } else {
          processarUsuario(usuario);
        }
      } else {
        handleError();
      }
    };

    fetchData();
  }, []);

  return {
    aprovado,
    situacao,
    isLoading,
    existe,
  };
};

export default AcessoApiStore;