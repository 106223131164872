import React from 'react';

const ServicosOferecidos = () => {
  return (
    <>
      <p
        style={{
          fontWeight: '800',
          fontFamily: 'sans-serif',
          color: '#116DA0',
        }}
      >
        2.1. API - Oportunidades Públicas Concluídas
      </p>
      <p>
        Essa API permite acessar informações sobre oportunidades públicas que já
        foram finalizadas. Ideal para análises retrospectivas e planejamento
        estratégico, ela fornece dados completos sobre processos encerrados.
      </p>
      <p>
        <strong>Principais funcionalidades:</strong>
      </p>
      <ul>
        <li>
          <strong>Rotas de famílias:</strong> Filtragem de oportunidades
          concluídas por categorias específicas.
        </li>
        <li>
          <strong>Busca por palavras-chave:</strong> Localização rápida de
          oportunidades através de termos relevantes.
        </li>
        <li>
          <strong>Busca por data:</strong> Consulta de oportunidades encerradas
          dentro de um período determinado.
        </li>
        <li>
          <strong>Busca por anexo:</strong> Acesso a documentos e arquivos
          relacionados às oportunidades finalizadas.
        </li>
        <li>
          <strong>Busca pelo número da oportunidade:</strong> Localização direta
          por um identificador específico.
        </li>
      </ul>
      <p
        style={{
          fontWeight: '800',
          fontFamily: 'sans-serif',
          color: '#116DA0',
        }}
      >
        2.2. API - Oportunidades Públicas em Aberto
      </p>
      <p>
        Essa API oferece acesso em tempo real às oportunidades públicas ainda
        disponíveis, permitindo que usuários acompanhem novas chances de
        participação.
      </p>
      <p>
        <strong>Principais funcionalidades:</strong>
      </p>
      <ul>
        <li>
          <strong>Rotas de famílias:</strong> Filtros por categoria de
          oportunidades em aberto.
        </li>
        <li>
          <strong>Busca por palavras-chave:</strong> Localização ágil de
          oportunidades relevantes.
        </li>
        <li>
          <strong>Busca por data:</strong> Consulta de oportunidades disponíveis
          dentro de um período específico.
        </li>
        <li>
          <strong>Busca por anexo:</strong> Acesso a documentos vinculados às
          oportunidades ainda ativas.
        </li>
        <li>
          <strong>Busca pelo número da oportunidade:</strong> Localização direta
          da oportunidade de interesse.
        </li>
      </ul>
      <p>
        Essas APIs proporcionam{' '}
        <strong>agilidade, precisão e eficiência</strong> na busca e análise de
        oportunidades, facilitando a tomada de decisão para os usuários da
        plataforma.
      </p>
    </>
  );
};

export default ServicosOferecidos;
