import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Form } from "@unform/web";
// import logo from '../../../assets/img/logos/logoBranca.svg';
import loader from "../../assets/icons/common/loading.svg";
import Colors from "../../assets/styles/colors";
import { eventCategory, logEvent } from "../../util/analytics";
import { checkMfaStatus, forgotPassword } from "../Auth/Cognito";
import { Button } from "../../components";
import Logo from "../../components/layout-components/Logo/Logo";
import UnformInput from "../../components/common-components/UnformInputs/UnformInput";
import { OnboardingContainer } from "../Auth/styles";
import AWS from "aws-sdk";


interface StylesProps{
  width:string;
  last:boolean;
}



const CreateContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
`;

const CreateBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 30%;
  padding: 2.5rem 3rem;
  background: ${Colors.white};
  box-shadow: 0px 1px 2px ${Colors.black}1a;
  border-radius: 4px;
  transition: width 1s;
  margin-top: -10%;
  @media (max-width: 1440px) {
    width: 50%;
  }
  @media (max-width: 960px) {
    margin: 1rem 0;
    width: 90%;
  }
  @media (max-width: 648px) {
    padding: 2rem;
    width: 100%;
  }
`;

const CreateTitle = styled.h1`
  align-self: flex-start;
  margin: 0 1rem 1rem 0;
  font: 700 1.5rem "Eurostile", "Open Sans", sans-serif;
  letter-spacing: 0;
  color: ${Colors.blue6};
  @media (max-width: 960px) {
    font-size: 1.3rem;
  }
  @media (max-width: 425px) {
    font-size: 1rem;
  }
`;

const ButtonsBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 648px) {
    flex-wrap: column;
    margin-top: 2.5rem;
  }
`;

const InputBox = styled.span`
  position: relative;
  width: ${(props:StylesProps) => props.width};
  margin-top: ${(props:StylesProps) => props.last && ".5rem"};

  @media (max-width: 960px) {
    width: 100%;
  }
`;

const RecoveryPasswordForm = styled(Form)`
  width: 95%;
  @media (max-width: 648px) {
    width: 100%;
    flex-wrap: column;
    margin-top: 2.5rem;
  }
`;

const LoginMessageError = styled.span`
  position: absolute;
  right: 0;
  bottom: -3rem;
`;

const TextError = styled.p`
  font: 500 0.8rem "Eurostile", "Open Sans", sans serif;
  color: ${Colors.red2};
  @media (max-width: 960px) {
    margin: 0.1rem 0;
  }
`;

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 3rem;
`;

const Loader = styled.img`
  width: 30px;
  height: 30px;
`;

const MFACodePage = (props:any) => {
  const formRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string>();
  const [isMfaEnabled, setIsMfaEnabled] = useState(false);

  const verifyMfa = async () => {
    const credentials = localStorage.getItem("petronect_credentials");

    if (!credentials) {
      console.error("Credenciais não encontradas no localStorage.");
      return;
    }

    try {
      const parsedCredentials = JSON.parse(credentials);
      const accessToken = parsedCredentials?.accessToken;

      if (!accessToken) {
        console.error("AccessToken não encontrado dentro de petronect_credentials.");
        return;
      }

      const mfaEnabled = await checkMfaStatus(accessToken);
      if (mfaEnabled) {
        setIsMfaEnabled(true);
      } else {
        setIsMfaEnabled(false);
      }
    } catch (error) {
      console.error("Erro ao analisar petronect_credentials:", error);
    }
  };

  useEffect(() => {
    verifyMfa(); // Chama a função assim que o componente é montado
  }, []);

  const cognito = new AWS.CognitoIdentityServiceProvider({
    region: "us-east-1",
  });
  const handleMFA = async (data: any) => {
    setIsLoading(true);
    setError(undefined);
  
    try {
      const mfaCode = data.number?.trim();
      if (!mfaCode) {
        throw new Error("Código MFA é obrigatório.");
      }
  
      // Pegamos os dados da sessão armazenados após o login
      const sessionData = sessionStorage.getItem("CognitoSession");
      if (!sessionData) {
        throw new Error("Sessão inválida. Faça login novamente.");
      }
  
      const session = JSON.parse(sessionData);
  
      if (!session.Session || !session.Username) {
        throw new Error("Dados de sessão inválidos.");
      }
  
      const params = {
        ChallengeName: "SOFTWARE_TOKEN_MFA",
        ClientId: `${process.env.REACT_APP_COGNITO_CLIENT_ID}`,
        Session: session.Session,
        ChallengeResponses: {
          USERNAME: session.Username,
          SOFTWARE_TOKEN_MFA_CODE: mfaCode,
        },
      };
  
      const response = await cognito.respondToAuthChallenge(params).promise();
  
      if (response.AuthenticationResult) {
        sessionStorage.setItem("idToken", response.AuthenticationResult.IdToken ?? "");
        sessionStorage.setItem("accessToken", response.AuthenticationResult.AccessToken ?? "");
        sessionStorage.setItem("refreshToken", response.AuthenticationResult.RefreshToken ?? "");
  
        props.history.push("/match-relevancia");
      } else {
        throw new Error("Código MFA inválido.");
      }
    } catch (err: any) {
      setError(err.message || "Código MFA inválido ou expirado.");
    } finally {
      setIsLoading(false);
    }
  };

  const renderError = () => {
    if (error && !isLoading) {
      return (
        <LoginMessageError>
          <TextError>{error}</TextError>
        </LoginMessageError>
      );
    }

    return null;
  };

  const renderButton = () => {
    if (isLoading) {
      return (
        <LoaderContainer>
          <Loader src={loader} />
        </LoaderContainer>
      );
    }

    return (
      <Button
        margin="2.5rem 0 0 0"
        marginTopMobile=".5rem"
        width="100%"
        height="3.5rem"
        color={Colors.white2}
        background={Colors.blue8}
      >
        Enviar
      </Button>
    );
  };

  return (
    <OnboardingContainer>
    <CreateContainer>
      <CreateBox>
        <Logo width="220px" widthMobile="30%" />
        <RecoveryPasswordForm onSubmit={handleMFA} ref={formRef} placeholder="Autenticação Multi-Fator">
          <CreateTitle>Autenticação Multi-Fator</CreateTitle>
          <InputBox last width="100%">
            <UnformInput
              name={"number"}
              type="number"
              required
              error={error}
              placeholder={"******"}
              labelText="Código"
            />
            {renderError()}
          </InputBox>
          <ButtonsBox>
            {renderButton()}
            {!isLoading && (
              <Button
                margin="0.2rem 0 0 0"
                marginMobile=".5rem 0 0 0"
                width="100%"
                height="3rem"
                color={Colors.gray8}
                backgroundColor={Colors.white}
                onClick={props.handleBackLogin}
                textDecoration="underline"
              >
                Voltar ao login
              </Button>
            )}
          </ButtonsBox>
        </RecoveryPasswordForm>
      </CreateBox>
    </CreateContainer>
    </OnboardingContainer>
  );
};

export default MFACodePage;
