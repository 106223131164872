import React, { useRef } from 'react';
import { useHistory } from 'react-router-dom';
import {
  SubmitButton,
  FormContainer,
  InputGroup,
  BackLink,
  InputField,
  FormTitle,
  FormWrapper,
  CheckboxInput,
  CheckboxGroup,
  Error,
  Text,
} from './styles';
import ApiStoreLogo from '../../assets/img/logos/ApiStore.svg';
import useApiStore from '../../hooks/useApiStore';
import { ToastContainer } from 'react-toastify';
import DowloadTerms from './DowloadTerms';

const ParticipeApiStoreForm: React.FC = () => {
  const history = useHistory();
  const formRef = useRef<HTMLFormElement>(null);

  const {
    formData,
    errors,
    handleSubmit,
    handleChange,
    handleCheckboxChange,
    isSubmitting,
    success,
  } = useApiStore({
    nome: '',
    sobrenome: '',
    email: '',
    telefone: '',
    cpf: '',
    cnpj: '',
    aceite: false,
  });

  const goBack = () => {
    history.goBack();
  };

  return (
    <>
      <FormContainer>
        <FormWrapper ref={formRef} onSubmit={handleSubmit}>
          <img src={ApiStoreLogo} alt="Logo da Api Store" width={80} />
          {success ? (
            <>
              <FormTitle style={{ fontSize: '18px' }}>
                Sua participação está em análise!{' '}
                <span role="img" aria-label="Foguete">
                  🚀
                </span>
              </FormTitle>
              <Text>
                Estamos revisando sua solicitação e retornaremos com uma
                resposta em até <strong>72 horas úteis</strong>. Fique atento ao seu e-mail para
                atualizações e acompanhe sua solicitação no menu <a href="/apistore">API Store</a>.
              </Text>
            </>
          ) : (
            <>
              <FormTitle>Ficha de Inscrição API Store</FormTitle>

              <InputGroup>
                <label htmlFor="nome">Nome</label>
                <InputField
                  name="nome"
                  type="text"
                  placeholder="Digite seu nome"
                  value={formData.nome}
                  onChange={handleChange}
                />
                {errors.nome && <Error>{errors.nome}</Error>}
              </InputGroup>

              <InputGroup>
                <label htmlFor="sobrenome">Sobrenome</label>
                <InputField
                  name="sobrenome"
                  type="text"
                  placeholder="Digite seu sobrenome"
                  value={formData.sobrenome}
                  onChange={handleChange}
                />
                {errors.sobrenome && <Error>{errors.sobrenome}</Error>}
              </InputGroup>

              <InputGroup>
                <label htmlFor="email">Email</label>
                <InputField
                  name="email"
                  type="email"
                  placeholder="nome@email.com"
                  value={formData.email}
                  onChange={handleChange}
                />
                {errors.email && <Error>{errors.email}</Error>}
              </InputGroup>

              <InputGroup>
                <label htmlFor="telefone">Telefone</label>
                <InputField
                  name="telefone"
                  type="text"
                  maxLength={19}
                  placeholder="+55 (99) 99999-9999"
                  value={formData.telefone}
                  onChange={handleChange}
                />
                {errors.telefone && <Error>{errors.telefone}</Error>}
              </InputGroup>

              <InputGroup>
                <label htmlFor="cpf">CPF</label>
                <InputField
                  name="cpf"
                  type="text"
                  maxLength={14}
                  placeholder="000.000.000-00"
                  value={formData.cpf}
                  onChange={handleChange}
                />
                {errors.cpf && <Error>{errors.cpf}</Error>}
              </InputGroup>

              <InputGroup>
                <label htmlFor="cnpj">CNPJ</label>
                <InputField
                  name="cnpj"
                  type="text"
                  maxLength={18}
                  placeholder="00.000.000/00-000"
                  value={formData.cnpj}
                  onChange={handleChange}
                />
                {errors.cnpj && <Error>{errors.cnpj}</Error>}
              </InputGroup>

              <CheckboxGroup>
                <CheckboxInput
                  id="aceite"
                  name="aceite"
                  type="checkbox"
                  checked={formData.aceite}
                  onChange={handleCheckboxChange}
                />
                <label htmlFor="aceite">
                  Ao prosseguir aceito os{' '}
                  <button
                    type="button"
                    onClick={DowloadTerms}
                    style={{
                      textDecoration: 'underline',
                      background: 'none',
                      border: 'none',
                      color: 'blue',
                    }}
                  >
                    Termos de uso
                  </button>
                  e a <a href="https://www.petronect.com.br/irj/go/km/docs/pccshrcontent/Site%20Content%20(Legacy)/Portal2018/pt/pdf/Politica%20de%20Privacidade%20v.2_38106051.pdf" target='_blank' rel="noopener noreferrer">Política de Privacidade</a>
                </label>
                {errors.aceite && <Error>{errors.aceite}</Error>}
              </CheckboxGroup>

              <SubmitButton type="submit" disabled={isSubmitting}>
                {isSubmitting ? 'Enviado' : 'Participar'}
              </SubmitButton>
            </>
          )}
        </FormWrapper>
      </FormContainer>

      <BackLink onClick={goBack}>
        <span>Voltar</span>
      </BackLink>
      <ToastContainer
        position="top-center"
        style={{
          marginTop: '15%',
          marginLeft: '8.5%',
        }}
      />
    </>
  );
};

export default ParticipeApiStoreForm;
