import React, { FC } from 'react';
import {
  CardContainer,
  ContainerPage,
  Loader,
  LoaderContainer,
} from './styles';
import CnpjInvalido from './components/CnpjInvalido';
import AcessoNaoPago from './components/AcessoNaoPago';
import AguardeResposta from './components/AguardeResposta';
import AcessoApiStore from '../../dataflow/thunks/AcessoApiStore';
import loader from '../../assets/icons/common/loading.svg';
import Documentacao from './components/Documentacao';

const ApiStorePage: FC = () => {
  const { aprovado, situacao, isLoading } = AcessoApiStore();

  return (
    <ContainerPage>
      {isLoading ? (
        <LoaderContainer>
          <Loader src={loader} />
        </LoaderContainer>
      ) : aprovado === false ? (
        <CardContainer>
          {situacao === 'aguardando' && <AguardeResposta />}
          {situacao === 'TaxaDeAcesso' && <AcessoNaoPago />}
          {situacao === 'CNPJInvalido' && <CnpjInvalido />}
        </CardContainer>
      ) : (
        <Documentacao />
      )}
    </ContainerPage>
  );
};

export default ApiStorePage;
